import { configureStore, combineReducers } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import buyerFlowReducer from 'store/buyerFlowSlice'
import buyerOnNetworkFlowReucer from 'store/buyerOnNetworkFlowSlice'
import logisticsFlowReducer from 'store/logisticsFlowSlice'
import logisticsBuyerFlowReducer from 'store/logisticsBuyerFlowSlice'
import sellerFlowReducer from 'store/sellerFlowSlice'
import sellerOnNetworkFlowReducer from 'store/sellerOnNetworkFlowSlice'
import sellerDataReducer from 'store/sellerDataSlice'
import sellerOnNetworkDataReducer from 'store/sellerOnNetworkDataSlice'
import logisticsDataReducer from 'store/logisticsDataSlice'
import logisticsBuyerDataReducer from 'store/logisticsBuyerDataSlice'
import buyerDataReducer from 'store/buyerDataSlice'
import homeDataReducer from 'store/homeDataSlice'
import buyerOnNetworkDataReducer from 'store/buyerOnNetworkDataSlice'
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const reducer = combineReducers({
  homeData: homeDataReducer,
  buyerFlow: buyerFlowReducer,
  logisticsFlow: logisticsFlowReducer,
  logisticsBuyerFlow: logisticsBuyerFlowReducer,
  sellerFlow: sellerFlowReducer,
  sellerOnNetworkFlow: sellerOnNetworkFlowReducer,
  buyerData: buyerDataReducer,
  sellerData: sellerDataReducer,
  sellerOnNetworkData: sellerOnNetworkDataReducer,
  logisticsData: logisticsDataReducer,
  logisticsBuyerData: logisticsBuyerDataReducer,
  buyerOnNetworkData: buyerOnNetworkDataReducer,
  buyerOnNetworkFlow: buyerOnNetworkFlowReucer,
})

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
