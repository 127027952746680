import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, notification } from 'antd'
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
import { PayloadContext } from 'context'
import { LoaderContext } from 'context/loader'
import { API, Flow, IsellerPostFlowOnNetwork, MockFlow } from 'interface'
import usePost from 'hooks/usePost'
import APIS from 'constants/api'
import { defaultSellerProgressFlowsOnNetwork, sellerProgressFlowsOnNetwork } from 'constants/constants'
import Watermark from 'assets/svg/watermark'
import CustomButton from 'components/Button'
import Navbar from 'components/Navbar'
import Loader from 'components/Loader'
import Stepper from 'components/Stepper'
import Footer from 'components/Footer'
import SellerSelectModal from 'components/SellerSelectModal'
import { updateSellerOnNetworkHtmlResponse } from 'store/sellerOnNetworkDataSlice'
import {
  updateSellerOnNetworkFlowFileGenerated,
  updateSellerOnNetworkFlowStatus,
  setSellerOnNetworkFlow,
  updateSellerOnNetworkOrderStatus,
} from 'store/sellerOnNetworkFlowSlice'
import {
  LoaderContainer,
  Container,
  FeedbackWrapper,
  Heading,
  HeadingWrapper,
  LeftContainer,
  LeftWrapper,
  Name,
  NameWrapper,
  RightContainer,
  RightWrapper,
  StepsWrapper,
  Wrapper,
  Header,
  Title,
  ButtonWrapper,
  RightSection,
  FlowHeading,
  EntireFlowContainer,
  ApiWrapper,
  OrderNameWrapper,
  Flowwrapper,
  OrderWrapper,
  Apis,
  FlowWrapper,
  ReportButtonWrapper,
  ApiTitle,
} from 'styles/pages/sellerOnNetworkProgress'

type NotificationType = 'success' | 'info' | 'warning' | 'error'
const SellerOnNetworkProgress = () => {
  const [selectModal, setSelectModal] = useState(false)
  const [timer, setTimer] = useState(false)
  const [reportLoader, setReportLoader] = useState(false)
  const [waiting, setWaiting] = useState(false)
  const [sellerWaiting, setSellerWaiting] = useState(false)
  const [cancelWaiting, setCancelWaiting] = useState(false)
  const [issueWaiting, setIssueWaiting] = useState(false)
  const [updateWaiting, setUpdateWaiting] = useState(false)
  const [issueStatusWaiting, setIssueStatusWaiting] = useState(false)
  const [statusWaiting, setStatusWaiting] = useState(false)
  const [duration, setDuration] = useState('')
  const [transactionId, setTransactionId] = useState('')
  const [issueId, setIssueId] = useState('')
  const [issueBtn, setIssueBtn] = useState(false)
  const [transactionBtn, setTransactionBtn] = useState(false)

  const [next, setNext] = useState(false)
  const [content, setContent] = useState(false)
  const [flowName, setFlowName] = useState('')
  const [flowLoading, setFlowLoading] = useState(false)
  const [rating, setRating] = useState(false)
  const { setLoader } = useContext(LoaderContext)
  const { payloadData } = useContext(PayloadContext)
  const { mutateAsync } = usePost()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const sellerOnNetworkFlowState = useSelector((state: any) => state.sellerOnNetworkFlow)
  const sellerOnNetworkDataState = useSelector((state: any) => state?.sellerOnNetworkData)
  const homeDataState = useSelector((state: any) => state?.homeData)
  const environment = homeDataState.environment

  const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
    api[type]({
      message: message,
      description: description,
      duration: 3,
    })
  }
  const [api, contextHolder] = notification.useNotification()

  const updateReduxFlowStatus1 = (event: any) => {
    const { flow_name, status } = event
    dispatch(updateSellerOnNetworkOrderStatus({ flowName: flow_name, status }))
  }
  const updateReduxFlowStatus = (event: any) => {
    const { flow_name, message } = event
    dispatch(updateSellerOnNetworkFlowStatus({ flowName: flow_name, message }))
  }

  const handleFileGenerateEvent = (event: any) => {
    const { flow_name } = event
    dispatch(updateSellerOnNetworkFlowFileGenerated({ flowName: flow_name, fileGenerated: true }))
  }

  const updateStatus = (input: {
    flow_name: string
    status: string
    orderTimer: string
    action: string
    api: string
    time: string
    transactionId: string
    issueId: string
    // oneMinTimer: string
  }) => {
    if (input.orderTimer == 'start' && input.action == 'on_update') {
      setDuration(input?.time)
      setSellerWaiting(true)
      setTransactionId(input?.transactionId)
      setTransactionBtn(true)
    }

    if (input.orderTimer == 'stop' && input.action == 'on_update') {
      setSellerWaiting(false)
    }

    if (input.orderTimer == 'start' && input.api == 'on_status') {
      setTransactionId(input?.transactionId)
      setTransactionBtn(true)
      setDuration(input?.time)
      setStatusWaiting(true)
    }

    if (input.orderTimer == 'stop' && input.api == 'on_status') {
      setStatusWaiting(false)
    }

    if (input.orderTimer == 'start' && input.action == '/status') {
      setTransactionId(input?.transactionId)
      setTransactionBtn(true)
      setDuration(input?.time)
      setStatusWaiting(true)
    }

    if (input.orderTimer == 'stop' && input.action == '/status') {
      setStatusWaiting(false)
    }

    if (input.orderTimer == 'start' && input.action == '/cancel') {
      setTransactionId(input?.transactionId)
      setTransactionBtn(true)
      setDuration(input?.time)
      setCancelWaiting(true)
    }

    if (input.orderTimer == 'stop' && input.action == '/cancel') {
      setCancelWaiting(false)
    }
    // /update

    if (input.orderTimer == 'start' && input.action == '/update') {
      setTransactionId(input?.transactionId)
      setIssueId(input?.issueId)
      setIssueBtn(true)
      setTransactionBtn(true)
      setDuration(input?.time)
      setUpdateWaiting(true)
    }

    if (input.orderTimer == 'stop' && input.action == '/update') {
      setUpdateWaiting(false)
    }
    // /issue

    if (input.orderTimer == 'start' && input.action == '/issue') {
      setTransactionId(input?.transactionId)
      setIssueId(input?.issueId)
      setIssueBtn(true)
      setTransactionBtn(true)
      setDuration(input?.time)
      setIssueWaiting(true)
    }

    if (input.orderTimer == 'stop' && input.action == '/issue') {
      setIssueWaiting(false)
    }
    // /issue_status

    if (input.orderTimer == 'start' && input.action == '/issue_status') {
      setTransactionId(input?.transactionId)
      setIssueBtn(true)
      setTransactionBtn(true)
      setIssueId(input?.issueId)
      setDuration(input?.time)
      setIssueStatusWaiting(true)
    }

    if (input.orderTimer == 'stop' && input.action == '/issue_status') {
      setIssueStatusWaiting(false)
    }

    if (input.orderTimer == 'start' && input.api == 'on_cancel') {
      setTransactionId(input?.transactionId)
      setTransactionBtn(true)
      setDuration(input?.time)
      setCancelWaiting(true)
    }

    if (input.orderTimer == 'stop' && input.api == 'on_cancel') {
      setCancelWaiting(false)
    }

    // /rating

    if (input?.action === '/rating' && input?.orderTimer === 'start') {
      setTransactionId(input?.transactionId)
      setTransactionBtn(true)
      setRating(true)
    }

    if (input?.action === '/rating' && input?.orderTimer === 'stop') setRating(false)

    if (
      input.flow_name == 'Flow 1: Order to confirm to fulfillment' &&
      input.orderTimer == 'start' &&
      input.action == '/update'
    ) {
      setTransactionId(input?.transactionId)
      setTransactionBtn(true)
      setDuration(input?.time)
      setWaiting(true)
    }

    if (
      input.flow_name == 'Flow 1: Order to confirm to fulfillment' &&
      input.orderTimer == 'stop' &&
      input.action == '/update'
    ) {
      setDuration(input?.time)
      setWaiting(false)
    }

    if (input.api == 'on_select' && input.orderTimer == 'start') {
      setSelectModal(true)
    } else if (input.api == 'on_confirm' && input.orderTimer == 'stop') {
      setSelectModal(true)
    } else {
      setSelectModal(false)
    }

    // if (input.api == 'on_confirm' && input.orderTimer == 'start') {
    //   setSelectModal(true)
    // } else if (input.api == 'on_confirm' && input.orderTimer == 'stop') {
    //   setSelectModal(false)
    // } else {
    //   setSelectModal(false)
    // }

    if (
      input.flow_name === 'Flow A1: Order to confirm to fulfillment (On-Network Logistics)' &&
      input.orderTimer == 'start' &&
      (input.api == 'on_confirm' || input.api == 'on_select')
    ) {
      setSelectModal(true)
      return
    } else {
      setSelectModal(false)
    }

    if (
      (input.flow_name == 'Flow A2: Merchant Side Full Order Cancellation RTO Flow (On-Network Logistics)' &&
        input.orderTimer == 'start' &&
        input.api == 'on_confirm') ||
      input.api == 'on_select'
    ) {
      setSelectModal(true)
    } else {
      setSelectModal(false)
    }

    if (
      input.flow_name == 'Flow A2: Merchant Side Full Order Cancellation RTO Flow (On-Network Logistics)' &&
      input.orderTimer == 'start' &&
      input.action == '/status'
    ) {
      setDuration(input?.time)
      setStatusWaiting(true)
    }

    if (
      input.flow_name == 'Flow A2: Merchant Side Full Order Cancellation RTO Flow (On-Network Logistics)' &&
      input.orderTimer == 'stop' &&
      input.action == '/status'
    ) {
      setStatusWaiting(false)
    }
  }

  const handleCallback = () => {
    const test_id = sellerOnNetworkDataState?.testId
    const url = `${process.env.REACT_APP_BASE_URL}/sse/${test_id}`
    const eventSource = new EventSource(url, { withCredentials: false })
    eventSource?.addEventListener('api_status', (event: any) => {
      setLoader(false)
      const data = JSON.parse(event.data)

      updateStatus(data)
      updateReduxFlowStatus1(data)
    })
    eventSource?.addEventListener('file_generate', (event: any) => {
      setFlowLoading(false)
      setLoader(false)
      setReportLoader(true)
      const data: any = JSON.parse(event.data)
      if (data?.success) {
        updateReduxFlowStatus(data)
        handleFileGenerateEvent(data)
        window.localStorage.setItem('reportFlag', 'true')
        setReportLoader(false)
        openNotificationWithIcon('success', 'File Generated Successfully', '')
      }
    })
  }

  useEffect(() => {
    if (!sellerOnNetworkFlowState.length) dispatch(setSellerOnNetworkFlow(sellerProgressFlowsOnNetwork))
    setNext(true)
  }, [])

  const handleDownload = async (fileUrl: string, flow_name: string, test_id: number) => {
    try {
      const response = await axios.get(fileUrl, {
        responseType: 'blob',
      })
      const downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(new Blob([response.data]))
      downloadLink.download =
        'seller-on-network' + ' ' + flow_name + '-' + test_id + '-' + new Date().toISOString() + '.html'
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (error) {
      return error
    }
  }

  const getGeneratedFile = async (flow_name: string) => {
    const test_id = sellerOnNetworkDataState?.testId
    try {
      const payload = {
        testId: test_id,
        flowName: flow_name,
      }
      const response = await mutateAsync({
        url: APIS.GET_REPORT,
        payload: payload,
        mockServer: true,
      })

      dispatch(updateSellerOnNetworkHtmlResponse(response.data.mesg))
      handleDownload(response.data.mesg, flow_name, test_id)
    } catch (error) {
      return error
    }
  }

  const startTest = async (name: string) => {
    setLoader(true)
    setFlowLoading(true)
    setFlowName(name)

    try {
      const pythonEnginePayload: Flow[] = []
      const mockpayload: MockFlow = {
        flows: [],
      }
      const parsedData = sellerOnNetworkDataState?.testData
      defaultSellerProgressFlowsOnNetwork.forEach((item: IsellerPostFlowOnNetwork) => {
        const apis: API[] = []
        if (name === item.flow_name) {
          item.order.forEach((item: string) => {
            const action: string = item.split('/')[1]
            const payload = payloadData[action]
            payload.context = {
              ...payload.context,
              bpp_id: parsedData.bpp_id,
              bpp_uri: parsedData.bpp_uri,
              core_version: parsedData.version,
              domain: parsedData.domain,
            }
            const obj = {
              route: item,
              payload,
            }
            apis.push(obj)
          })

          const finalObj = {
            flow_name: name,
            order: item.order,
            logistics_order: item.logisticsOrder,
            test_id: sellerOnNetworkDataState.testId,
            apis,
            environment: environment,
          }
          pythonEnginePayload.push(finalObj)
        }

        const Payload = {
          ...parsedData,
          test_id: sellerOnNetworkDataState?.testId,
          flows: pythonEnginePayload,
        }
        Object.assign(mockpayload, Payload)
      })

      const startTestApi = async (testId: string) => {
        let xml
        if (
          name == 'Flow A1: Order to confirm to fulfillment (On-Network Logistics)' ||
          name == 'Flow A2: Merchant Side Full Order Cancellation RTO Flow (On-Network Logistics)' ||
          name == 'Flow A3: Issue & Grievance Management (IGM) - Cascaded Flow' ||
          name == 'Flow A4: Rating (Cascaded Flow)'
        ) {
          xml = 'OnNetworkTest'
        } else {
          xml = 'ApiFlowTest'
        }

        const StartTestPayload = {
          test_id: testId,
          flow_name: name,
          xml_name: xml,
        }

        try {
          setContent(false)
          const timeout = setTimeout(() => {
            setContent(true)
            setLoader(false)
          }, 120000)
          const response = await mutateAsync({
            url: APIS.POST_START_TEST_BUYER,
            payload: StartTestPayload,
          })

          if (response) {
            setLoader(true)
            clearTimeout(timeout)
            setContent(false)
          }
        } catch (error) {
          return error
        }
      }

      await mutateAsync({
        url: APIS.POST_TEST_FLOW,
        payload: mockpayload,
        mockServer: true,
      })
      await mutateAsync({
        url: APIS.POST_FLOW,
        payload: pythonEnginePayload,
      })

      handleCallback()
      startTestApi(sellerOnNetworkDataState?.testId)
    } catch (error: any) {
      openNotificationWithIcon('error', JSON.stringify(error.message), 'Please try after some time')
      setLoader(false)
    }
  }
  const status = sellerOnNetworkFlowState.some((item: any) => item.flowStatus === 'started')

  const copyTrsactionId = transactionId
  const handleCopyClickTrans = async () => {
    try {
      await navigator.clipboard.writeText(copyTrsactionId)
    } catch (err) {
      return err
    }
  }
  const copyIssueId = issueId
  const handleCopyClickIssue = async () => {
    try {
      await navigator.clipboard.writeText(copyIssueId)
    } catch (err) {
      return err
    }
  }

  return (
    <Wrapper>
      {contextHolder}
      <Navbar />
      <Container>
        <LeftWrapper>
          <NameWrapper>
            <Name>Pramaan</Name>
          </NameWrapper>
          <LeftContainer>
            <StepsWrapper>
              <Stepper current={2} next={next} navi="/seller-on-network-testing" />
            </StepsWrapper>
            <Watermark />
            <FeedbackWrapper>
              <CustomButton variant="text" label={'Feedback'} onClick={() => navigate('/alpha/feedback')} />
            </FeedbackWrapper>
          </LeftContainer>
        </LeftWrapper>
        <RightWrapper>
          <HeadingWrapper>
            <Heading>Execute Testing & Review Results</Heading>
          </HeadingWrapper>
          <RightContainer>
            <RightSection>
              <Header>
                <Title>Test Scenarios</Title>
              </Header>
              <FlowWrapper>
                {sellerOnNetworkFlowState.map((flow: any, index: number) => {
                  return (
                    <EntireFlowContainer key={index}>
                      <Flowwrapper>
                        <FlowHeading>{flow.flow_name}</FlowHeading>
                        <ReportButtonWrapper>
                          {flow.fileGenerated ? (
                            <Button
                              type="text"
                              icon={<DownloadOutlined style={{ fontSize: '18px', color: '#21ADE8' }} />}
                              size={'middle'}
                              onClick={() => getGeneratedFile(flow.flow_name)}
                            >
                              Download Report
                            </Button>
                          ) : (
                            ''
                          )}
                        </ReportButtonWrapper>

                        <ButtonWrapper>
                          {flowLoading && flow.flowStatus == 'started' ? (
                            <Button>
                              <LoaderContainer>
                                <LoadingOutlined style={{ fontSize: '20px', color: '#666666' }} />
                                Testing
                              </LoaderContainer>
                            </Button>
                          ) : (
                            <CustomButton
                              label={'Start'}
                              variant={
                                flow.flowStatus == 'completed'
                                  ? 'disabled'
                                  : sellerOnNetworkFlowState.some((item: any) => item.flowStatus === 'started')
                                  ? 'disabled'
                                  : 'contained'
                              }
                              onClick={() => startTest(flow.flow_name)}
                              disabled={flow.flowStatus == 'completed' ? true : status ? true : false}
                            />
                          )}
                        </ButtonWrapper>
                      </Flowwrapper>

                      <ApiWrapper>
                        <Apis>
                          <ApiTitle>Retail:</ApiTitle>
                          {flow?.order?.map((it: any, ind: number) => {
                            return (
                              <OrderWrapper key={ind}>
                                <OrderNameWrapper> {it.api}</OrderNameWrapper>
                              </OrderWrapper>
                            )
                          })}
                        </Apis>
                      </ApiWrapper>
                      {flow?.logisticsOrder && (
                        <ApiWrapper>
                          <Apis>
                            <ApiTitle>Logistics Buyer:</ApiTitle>
                            {flow?.logisticsOrder?.map((it: any, ind: number) => {
                              return (
                                <OrderWrapper key={ind}>
                                  <OrderNameWrapper> {it}</OrderNameWrapper>
                                </OrderWrapper>
                              )
                            })}
                          </Apis>
                        </ApiWrapper>
                      )}
                    </EntireFlowContainer>
                  )
                })}
              </FlowWrapper>
            </RightSection>
          </RightContainer>
        </RightWrapper>
      </Container>
      {selectModal && (
        <SellerSelectModal
          heading="Select Your Item From List"
          isModalOpen={selectModal}
          setIsModalOpen={setSelectModal}
          setTimer={setTimer}
          callback={handleCallback}
          flowName={flowName}
        />
      )}

      {timer && <Loader isModalOpen={timer} text={'Waiting for 3 minutes for search call'} />}
      {reportLoader && <Loader isModalOpen={reportLoader} text={'Generating Report'} />}
      {waiting && (
        <Loader
          isModalOpen={waiting}
          text={'Waiting for update call from Logistics Buyer'}
          subText1={`  TransactionId: ${transactionId}`}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
        />
      )}
      {sellerWaiting && (
        <Loader
          isModalOpen={sellerWaiting}
          text={`Waiting ${duration} minutes for on_update call from Seller`}
          subText1={`  TransactionId: ${transactionId}`}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
        />
        // <Loader isModalOpen={sellerWaiting} text={`Waiting ${duration} minutes for on_update call from Seller`} />
      )}
      {cancelWaiting && (
        <Loader
          isModalOpen={cancelWaiting}
          text={`Waiting ${duration}  minutes for on_cancel call from Seller`}
          subText1={`  TransactionId: ${transactionId}`}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
        />
      )}
      {updateWaiting && (
        <Loader
          isModalOpen={updateWaiting}
          text={`Waiting ${duration}  minutes for on_update call from Seller`}
          subText1={`  TransactionId: ${transactionId}`}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
        />
      )}
      {issueWaiting && (
        <Loader
          isModalOpen={issueWaiting}
          text={`Waiting ${duration}  minutes for on_issue response`}
          subText1={` TransactionId: ${transactionId}`}
          subText2={` IssueID : ${issueId}  `}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
          issueBtn={issueBtn}
          issueBtnFn={handleCopyClickIssue}
        />
      )}
      {issueStatusWaiting && (
        <Loader
          isModalOpen={issueStatusWaiting}
          text={`Waiting ${duration}  minutes for on_issue_status response`}
          subText1={` TransactionId: ${transactionId}`}
          subText2={` IssueID : ${issueId}  `}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
          issueBtn={issueBtn}
          issueBtnFn={handleCopyClickIssue}
        />
      )}
      {statusWaiting && (
        <Loader
          isModalOpen={statusWaiting}
          text={`Waiting ${duration}  minutes for on_status call from Seller`}
          subText1={`  TransactionId: ${transactionId}`}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
        />
      )}
      {content && <Loader isModalOpen={content} text={'Please wait for 15 - 20 minutes as this test is in queue'} />}
      {rating && (
        <Loader
          isModalOpen={true}
          text={`Waiting  ${duration} minutes for on_rating update`}
          subText1={`  TransactionId: ${transactionId}`}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
        />
      )}
      <Footer />
    </Wrapper>
  )
}

export default SellerOnNetworkProgress
