import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, notification } from 'antd'
import usePost from 'hooks/usePost'
import { defaultSellerProgressFlows, sellerProgressFlows } from 'constants/constants'
import APIS from 'constants/api'
import Navbar from 'components/Navbar'
import Loader from 'components/Loader'
import CustomButton from 'components/Button'
import Stepper from 'components/Stepper'
import Footer from 'components/Footer'
import Watermark from 'assets/svg/watermark'
import { PayloadContext } from 'context'
import { LoaderContext } from 'context/loader'
import SellerOffNetworkSelectModal from 'components/SellerOffNetworkSelectModal'
import { API, Flow, IsellerPostFlowOffNetwork, MockFlow } from 'interface'
import { updateSellerOffNetworkHtmlResponse } from 'store/sellerDataSlice'
import {
  setSellerOffNetworkFlow,
  updateSellerOffNetworkFlowFileGenerated,
  updateSellerOffNetworkOrderStatus,
  updateSellerOffnNetworkFlowStatus,
} from 'store/sellerFlowSlice'
import { LoaderContainer } from 'styles/components/Loader'
import {
  EntireFlowContainer,
  FlowHeading,
  FlowWrapper,
  RightSection,
  ButtonWrapper,
  Header,
  Title,
  Container,
  FeedbackWrapper,
  Heading,
  HeadingWrapper,
  LeftContainer,
  LeftWrapper,
  Name,
  NameWrapper,
  RightContainer,
  RightWrapper,
  StepsWrapper,
  Wrapper,
  ApiWrapper,
  Apis,
  Flowwrapper,
  OrderNameWrapper,
  OrderWrapper,
  ReportButtonWrapper,
} from 'styles/pages/flowProgress'

type NotificationType = 'success' | 'info' | 'warning' | 'error'
const FlowProgress = () => {
  const { setLoader } = useContext(LoaderContext)
  const { payloadData } = useContext(PayloadContext)
  const { mutateAsync } = usePost()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // /popup states
  const [info, setInfo] = useState(false)
  const [isWaiting, setIsWaiting] = useState(false)
  const [incrementalWait, setIncrementalWait] = useState(false)
  const [complaineWait, setComplaineWait] = useState(false)
  const [updateWait, setUpdateWait] = useState(false)
  const [orderFulWait, setOrderFulWait] = useState(false)
  const [statusWaiting, setStatusWaiting] = useState(false)
  const [rating, setRating] = useState(false)
  const [updateWaiting, setUpdateWaiting] = useState(false)
  const [cancelWaiting, setCancelWaiting] = useState(false)
  const [duration, setDuration] = useState('')
  const [issueWaiting, setIssueWaiting] = useState(false)
  const [issueStatusWaiting, setIssueStatusWaiting] = useState(false)

  const [flowLoading, setFlowLoading] = useState(false)
  const [content, setContent] = useState(false)
  const [selectModal, setSelectModal] = useState(false)
  const [flowName, setFlowName] = useState('')
  const [issueId, setIssueId] = useState('')
  const [transactionId, setTransactionId] = useState('')
  const [transactionBtn, setTransactionBtn] = useState(false)
  const [next, setNext] = useState(false)
  const [count, setCount] = useState(true)
  const [issueBtn, setIssueBtn] = useState(false)

  const sellerOffNetworkFlowState = useSelector((state: any) => state.sellerFlow)
  const sellerOffNetworkDataState = useSelector((state: any) => state?.sellerData)
  const { testId } = sellerOffNetworkDataState
  const homeDataState = useSelector((state: any) => state?.homeData)
  const environment = homeDataState.environment

  const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
    api[type]({
      message: message,
      description: description,
      duration: 3,
    })
  }
  const [api, contextHolder] = notification.useNotification()

  const updateReduxFlowStatus1 = (event: any) => {
    const { flow_name, status } = event
    dispatch(updateSellerOffNetworkOrderStatus({ flowName: flow_name, status }))
  }
  const updateReduxFlowStatus = (event: any) => {
    const { flow_name, message } = event
    dispatch(updateSellerOffnNetworkFlowStatus({ flowName: flow_name, message }))
  }

  const handleFileGenerateEvent = (event: any) => {
    const { flow_name } = event
    dispatch(updateSellerOffNetworkFlowFileGenerated({ flowName: flow_name, fileGenerated: true }))
  }

  const updateFlowStatus = (input: {
    flow_name: string
    status: string
    orderTimer: string
    // oneMinTimer: string
    transactionId: string
    issueId: string
    count: number
    action: string
    time: string
  }) => {
    if (input?.flow_name) {
      setLoader(false)
    }

    setDuration(input?.time)
    if (input?.orderTimer === 'start') setIsWaiting(true)
    if (input?.orderTimer === 'stop') setIsWaiting(false)
    setTransactionId(input?.transactionId)
    setTransactionBtn(true)
    if (input?.orderTimer === 'start' && input?.action === '/info') {
      setInfo(true)
      setTransactionId(input?.transactionId)
      setTransactionBtn(true)
    }

    if (input?.orderTimer === 'stop' && input?.action === '/info') setInfo(false)
    // /update
    if (input?.action === '/update' && input?.orderTimer === 'start') {
      setUpdateWaiting(true)
      setTransactionId(input?.transactionId)
    }

    if (input?.action === '/update' && input?.orderTimer === 'stop') setUpdateWaiting(false)
    // /status
    if (input?.action === '/status' && input?.orderTimer === 'start') {
      setStatusWaiting(true)
      setTransactionId(input?.transactionId)
    }

    if (input?.action === '/status' && input?.orderTimer === 'stop') setStatusWaiting(false)
    // /issue

    if (input.orderTimer == 'start' && input.action == '/issue') {
      setTransactionId(input?.transactionId)
      setIssueId(input?.issueId)
      setIssueBtn(true)
      setTransactionBtn(true)
      setDuration(input?.time)
      setIssueWaiting(true)
    }

    if (input.orderTimer == 'stop' && input.action == '/issue') {
      setIssueWaiting(false)
    }
    // /issue_status

    if (input.orderTimer == 'start' && input.action == '/issue_status') {
      setTransactionId(input?.transactionId)
      setIssueBtn(true)
      setTransactionBtn(true)
      setIssueId(input?.issueId)
      setDuration(input?.time)
      setIssueStatusWaiting(true)
    }

    if (input.orderTimer == 'stop' && input.action == '/issue_status') {
      setIssueStatusWaiting(false)
    }

    // /rating
    if (input?.action === '/rating' && input?.orderTimer === 'start') {
      setRating(true)
      setTransactionId(input?.transactionId)
    }

    if (input?.action === '/rating' && input?.orderTimer === 'stop') setRating(false)
    // /cancel
    if (input?.action === '/cancel' && input?.orderTimer === 'start') {
      setCancelWaiting(true)
      setTransactionId(input?.transactionId)
    }

    if (input?.action === '/cancel' && input?.orderTimer === 'stop') setCancelWaiting(false)

    if (input?.flow_name === 'Flow 10: Search & Custom Menu') {
      setIssueId(input?.issueId)
      setIssueBtn(true)
      setTransactionId(input?.transactionId)
      setTransactionBtn(true)
      if (input?.orderTimer === 'start') setIncrementalWait(true)
      if (input?.orderTimer === 'stop') setIncrementalWait(false)
    }

    if (input?.flow_name === 'Flow 3: Merchant Side Partial Order Cancellation') {
      setTransactionId(input?.transactionId)
      setTransactionBtn(true)
      if (input?.orderTimer === 'start') setUpdateWaiting(true)
      if (input?.orderTimer === 'stop') setUpdateWaiting(false)
    }

    if (input?.flow_name === 'Flow 8: Issue & Grievance Management (IGM)') {
      if (input?.issueId) {
        setIssueId(input?.issueId)
        setIssueBtn(true)
        setTransactionId(input?.transactionId)
        setTransactionBtn(true)
        if (input?.orderTimer === 'start') setComplaineWait(true)
        if (input?.orderTimer === 'stop') setComplaineWait(false)
      } else {
        setTransactionId(input?.transactionId)
        setTransactionBtn(true)
        if (input?.action === 'on_update' && input?.orderTimer === 'start') setUpdateWait(true)
        if (input?.action === 'on_update' && input?.orderTimer === 'stop') setUpdateWait(false)
      }
    }

    if (input?.flow_name === 'Flow 1: Order to confirm to fulfillment') {
      setTransactionId(input?.transactionId)
      setTransactionBtn(true)
      if (input?.count === 2) {
        setCount(false)
      }

      if (input?.orderTimer === 'start') setOrderFulWait(true)
      if (input?.orderTimer === 'stop') setOrderFulWait(false)
    }

    if (input?.flow_name === 'Flow 12: Self Pickup' && input?.action === '/status') {
      setTransactionId(input?.transactionId)
      setTransactionBtn(true)
      if (input?.orderTimer === 'start') setOrderFulWait(true)
      if (input?.orderTimer === 'stop') setOrderFulWait(false)
    }
  }

  const handleDownload = async (fileUrl: string, flow_name: string, test_id: number) => {
    try {
      const response = await axios.get(fileUrl, {
        responseType: 'blob',
      })
      const downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(new Blob([response.data]))
      downloadLink.download =
        'seller-off-network' + ' ' + flow_name + '-' + test_id + '-' + new Date().toISOString() + '.html'
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (error) {
      return error
    }
  }
  const getGeneratedFile = async (flow_name: string) => {
    const test_id = sellerOffNetworkDataState?.testId

    try {
      const payload = {
        testId: testId,
        flowName: flow_name,
      }
      const response = await mutateAsync({
        url: APIS.GET_REPORT,
        payload: payload,
        mockServer: true,
      })
      dispatch(updateSellerOffNetworkHtmlResponse(response.data.mesg))
      handleDownload(response.data.mesg, flow_name, test_id)
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    if (!sellerOffNetworkFlowState.length) dispatch(setSellerOffNetworkFlow(sellerProgressFlows))

    setNext(true)
  }, [])

  const copyTrsactionId = transactionId
  const handleCopyClickTrans = async () => {
    try {
      await navigator.clipboard.writeText(copyTrsactionId)
    } catch (err) {
      return err
    }
  }
  const copyIssueId = issueId
  const handleCopyClickIssue = async () => {
    try {
      await navigator.clipboard.writeText(copyIssueId)
    } catch (err) {
      return err
    }
  }

  const handleCallback = () => {
    setLoader(true)
    const test_id = sellerOffNetworkDataState?.testId
    const url = `${process.env.REACT_APP_BASE_URL}/sse/${test_id}`
    const eventSource = new EventSource(url, { withCredentials: false })
    eventSource?.addEventListener('api_status', (event: any) => {
      const data = JSON.parse(event.data)
      updateFlowStatus(data)
      updateReduxFlowStatus1(data)
    })
    eventSource?.addEventListener('file_generate', (event: any) => {
      setFlowLoading(false)

      const data: any = JSON.parse(event.data)
      if (data?.success) {
        updateReduxFlowStatus(data)
        handleFileGenerateEvent(data)
        window.localStorage.setItem('reportFlag', 'true')

        openNotificationWithIcon('success', 'File Generated Successfully', '')
      }
    })
  }
  const startTest = async (name: string) => {
    setFlowLoading(true)

    if (name !== 'info-calls') {
      setLoader(true)
    }

    setFlowName(name)
    try {
      const pythonEnginePayload: Flow[] = []
      const mockpayload: MockFlow = {
        flows: [],
      }

      const parsedData = sellerOffNetworkDataState?.testData

      defaultSellerProgressFlows.forEach((item: IsellerPostFlowOffNetwork) => {
        const apis: API[] = []
        if (name === item.flow_name) {
          item.order.forEach((item: string) => {
            const action: string = item.split('/')[1]
            const payload = payloadData[action]
            payload.context = {
              ...payload.context,
              bpp_id: parsedData.bpp_id,
              bpp_uri: parsedData.bpp_uri,
              core_version: parsedData.version,
              domain: parsedData.domain,
            }
            const obj = {
              route: item,
              payload,
            }
            apis.push(obj)
          })
          const finalObj = {
            flow_name: name,
            order: item.order,
            test_id: sellerOffNetworkDataState.testId,
            apis,
            environment_type: environment,
          }

          pythonEnginePayload.push(finalObj)
        }

        const Payload = {
          ...parsedData,
          test_id: sellerOffNetworkDataState?.testId,
          flows: pythonEnginePayload,
          environment_type: environment,
        }
        Object.assign(mockpayload, Payload)
      })

      const startTestApi = async (testId: string) => {
        const StartTestPayload = {
          test_id: testId,
          flow_name: name,
          xml_name: 'ApiFlowTest',
        }

        try {
          setContent(false)
          const timeout = setTimeout(() => {
            setContent(true)
            setLoader(false)
          }, 120000)
          const response = await mutateAsync({
            url: APIS.POST_START_TEST,
            payload: StartTestPayload,
          })

          if (response) {
            clearTimeout(timeout)
            setContent(false)
          }
        } catch (error) {
          return error
        }
      }

      await mutateAsync({
        url: APIS.POST_TEST_FLOW,
        payload: mockpayload,
        mockServer: true,
      })
      await mutateAsync({
        url: APIS.POST_FLOW,
        payload: pythonEnginePayload,
      })

      if (name == 'info-calls') {
        setSelectModal(true)
        setLoader(false)
      } else {
        handleCallback()

        startTestApi(sellerOffNetworkDataState?.testId)
      }
    } catch (error: any) {
      openNotificationWithIcon('error', JSON.stringify(error.message), 'Please try after some time')
    }
  }

  const status = sellerOffNetworkFlowState.some((item: any) => item.flowStatus === 'started')

  return (
    <Wrapper>
      {contextHolder}
      <Navbar />

      <Container>
        <LeftWrapper>
          <NameWrapper>
            <Name>Pramaan</Name>
          </NameWrapper>
          <LeftContainer>
            <StepsWrapper>
              <Stepper current={2} next={next} navi="/seller-off-network-testing" />
            </StepsWrapper>
            <Watermark />
            <FeedbackWrapper>
              <CustomButton variant="text" label={'Feedback'} onClick={() => navigate('/alpha/feedback')} />
            </FeedbackWrapper>
          </LeftContainer>
        </LeftWrapper>
        <RightWrapper>
          <HeadingWrapper>
            <Heading>Execute Testing & Review Results</Heading>
          </HeadingWrapper>
          <RightContainer>
            <RightSection>
              <Header>
                <Title>Test Scenarios</Title>
              </Header>
              <FlowWrapper>
                {sellerOffNetworkFlowState.map((flow: any, index: number) => {
                  return (
                    <EntireFlowContainer key={index}>
                      <Flowwrapper>
                        <FlowHeading>{flow.flow_name}</FlowHeading>
                        <ReportButtonWrapper>
                          {flow.fileGenerated ? (
                            <Button
                              type="text"
                              icon={<DownloadOutlined style={{ fontSize: '18px', color: '#21ADE8' }} />}
                              size={'middle'}
                              onClick={() => getGeneratedFile(flow.flow_name)}
                            >
                              Download Report
                            </Button>
                          ) : (
                            ''
                          )}
                        </ReportButtonWrapper>

                        <ButtonWrapper>
                          {flowLoading && flow.flowStatus == 'started' ? (
                            <Button>
                              <LoaderContainer>
                                <LoadingOutlined style={{ fontSize: '20px', color: '#666666' }} />
                                Testing
                              </LoaderContainer>
                            </Button>
                          ) : (
                            <CustomButton
                              label={'Start'}
                              variant={
                                flow.flowStatus == 'completed'
                                  ? 'disabled'
                                  : sellerOffNetworkFlowState.some((item: any) => item.flowStatus === 'started')
                                  ? 'disabled'
                                  : 'contained'
                              }
                              onClick={() => startTest(flow.flow_name)}
                              disabled={flow.flowStatus == 'completed' ? true : status ? true : false}
                            />
                          )}
                        </ButtonWrapper>
                      </Flowwrapper>

                      <ApiWrapper>
                        <Apis>
                          {flow?.order?.map((it: any, ind: number) => {
                            return (
                              <OrderWrapper key={ind}>
                                <OrderNameWrapper> {it.api}</OrderNameWrapper>
                              </OrderWrapper>
                            )
                          })}
                        </Apis>
                      </ApiWrapper>
                    </EntireFlowContainer>
                  )
                })}
              </FlowWrapper>
            </RightSection>
          </RightContainer>
        </RightWrapper>
      </Container>
      {/* {reportLoader && <Loader isModalOpen={reportLoader} text={'Generating Report'} />} */}
      {content && <Loader isModalOpen={content} text={'Please wait for 15 - 20 minutes as this test is in queue'} />}

      {info && (
        <Loader
          isModalOpen={true}
          text={`Waiting for  ${duration} minute `}
          subText={` Note:  Send on_info to https://ondc-mock-server-dev.thewitslab.com/seller with `}
          subText1={` TransactionId : ${transactionId}`}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
        />
      )}
      {isWaiting && (
        <Loader
          isModalOpen={true}
          text={`Waiting for  ${duration} minute `}
          subText={` Note:  Send on_update to https://ondc-mock-server-dev.thewitslab.com/seller with `}
          subText1={` TransactionId : ${transactionId}`}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
        />
      )}
      {incrementalWait && (
        <Loader
          isModalOpen={true}
          text={`Waiting  ${duration} minutes for incremental refresh  `}
          subText1={`TransactionId: ${transactionId}`}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
        />
      )}
      {complaineWait && (
        <Loader
          isModalOpen={true}
          text={'Waiting '}
          subText={`Note: Awaiting the seller's response to the query with  `}
          subText1={` TransactionId: ${transactionId}`}
          subText2={` IssueID : ${issueId}  `}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
          issueBtn={issueBtn}
          issueBtnFn={handleCopyClickIssue}
        />
      )}
      {updateWait && (
        <Loader
          isModalOpen={true}
          text={'Waiting '}
          subText={`Note: Waiting  ${duration} minute for onUpdate call from seller for refund and Awaiting the seller's response to the query with  `}
          subText1={` TransactionId: ${transactionId}`}
          subText2={` IssueID : ${issueId}  `}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
        />
      )}
      {orderFulWait && (
        <Loader
          isModalOpen={true}
          text={'Waiting '}
          subText={
            count
              ? `Note: Waiting  ${duration} minute for status update till order picked up with  `
              : `Note: Waiting  ${duration} minute for status update till order delivery  `
          }
          subText1={`  TransactionId: ${transactionId}`}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
        />
      )}
      {statusWaiting && (
        <Loader
          isModalOpen={true}
          text={`Waiting  ${duration} minutes for on_status update`}
          subText1={`  TransactionId: ${transactionId}`}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
        />
      )}
      {rating && (
        <Loader
          isModalOpen={true}
          text={`Waiting  ${duration} minutes for on_rating update`}
          subText1={`  TransactionId: ${transactionId}`}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
        />
      )}
      {updateWaiting && (
        <Loader
          isModalOpen={true}
          text={`Waiting  ${duration} minutes for on_update responses`}
          subText1={`  TransactionId: ${transactionId}`}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
        />
      )}
      {cancelWaiting && (
        <Loader
          isModalOpen={true}
          text={`Waiting  ${duration} minutes for on_cancel `}
          subText1={`  TransactionId: ${transactionId}`}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
        />
      )}
      {issueWaiting && (
        <Loader
          isModalOpen={issueWaiting}
          text={`Waiting ${duration}  minutes for on_issue response`}
          subText1={` TransactionId: ${transactionId}`}
          subText2={` IssueID : ${issueId}  `}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
          issueBtn={issueBtn}
          issueBtnFn={handleCopyClickIssue}
        />
      )}
      {issueStatusWaiting && (
        <Loader
          isModalOpen={issueStatusWaiting}
          text={`Waiting ${duration}  minutes for on_issue_status response`}
          subText1={` TransactionId: ${transactionId}`}
          subText2={` IssueID : ${issueId}  `}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
          issueBtn={issueBtn}
          issueBtnFn={handleCopyClickIssue}
        />
      )}

      <SellerOffNetworkSelectModal
        heading="Select Your Item From List"
        isModalOpen={selectModal}
        setIsModalOpen={selectModal ? setSelectModal : setSelectModal}
        callback={handleCallback}
        flowName={flowName}
      />

      <Footer />
    </Wrapper>
  )
}

export default FlowProgress
