import styled from 'styled-components'

export const FlowStatusIcon = styled.div``
export const RightSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const EntireFlowContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  // width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  margin-bottom: 10px;
`

export const ButtonWrapper = styled.div`
  margin: 5px auto;
  button {
    height: 44px;
    width: 100px;
  }
  @media (max-width: 1200px) {
    button {
      height: 38px;
      width: 85px;
    }
  }
`

export const FlowHeading = styled.h3`
  color: #333;
  font-size: 18px;
  font-weight: 600;
  width: 90%;
`

export const Note = styled.p`
  font-size: 15px;
  font-weight: 500;
`
export const Notes = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
`
export const ProgressFlowWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const ApiCallsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`
export const ApiCalls = styled.p`
  color: #b9b9b9;
  display: flex;
  align-items: center;
`
export const Header = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 10px;
  width: 98%;
  justify-content: space-between;
  border-bottom: 1px solid #f4f4f4;
`
export const Title = styled.div`
  color: #333;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  margin-left: 5px;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: calc(100% - 150px);
`
export const FeedbackWrapper = styled.div``
export const Heading = styled.p`
  color: #606161;
  font-size: 26px;
  font-weight: 600;
  line-height: 40px;
`

export const HeadingWrapper = styled.div`
  height: 44px;
  margin: 5px;
`
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-around;
  padding: 20px;
  width: 300px;
  height: 67vh;
  border-radius: 14px;
  border: 1px solid #e8e7ea;
  background: var(--Basic-white, #fff);
  gap: 10px;
  @media (max-width: 1200px) {
    width: 250px;
    height: 55vh;
  }
`

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 4px;
`

export const Name = styled.h3`
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
`

export const NameWrapper = styled.div`
  width: 289px;
  background: linear-gradient(92deg, #1c75bc 6.62%, #00aeef 51.82%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 5px;
`
export const RightContainer = styled.div`
  width: 69vw;
  height: 67vh;
  border-radius: 14px;
  border: 1px solid #e8e7ea;
  background: #fff;
  padding: 20px;
  @media (max-width: 1200px) {
    width: 62vw;
    height: 55vh;
  }
`
export const RightWrapper = styled.div``
export const StepsWrapper = styled.div``

export const Wrapper = styled.div`
  background: #f9f9fa;
  height: 100vh;
`

export const ApiWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`
export const OrderNameWrapper = styled.p`
  color: var(--Neutral-neutral-black-100, #b9b9b9);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`
export const Flowwrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
`
export const OrderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
export const Apis = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  gap: 20px;
  flex-wrap: wrap;
`
export const FlowWrapper = styled.div`
  height: 85%;
  overflow: hidden scroll;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 40px;
    background: #d9d9d9;
  }
`
export const ReportButtonWrapper = styled.div`
  width: 150px;
  margin: 0 15px;
  button {
    padding: 0 2px;
  }
`
