export const sellerProgressFlows = [
  {
    flowStatus: 'pending',
    flow_name: 'Flow 1: Order to confirm to fulfillment',
    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/status' },
      { status: 'pending', api: '/track' },
      { status: 'pending', api: '/status' },
    ],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 2: Buyer Side Full Order Cancellation',
    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/cancel' },
    ],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 3: Merchant Side Partial Order Cancellation',
    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/update' },
    ],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 4: Merchant Side Full Order Cancellation (RTO Flow)',
    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/status' },
      { status: 'pending', api: '/cancel' },
      { status: 'pending', api: '/update' },
      { status: 'pending', api: '/status' },
    ],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 5: Merchant Side Full Order Cancellation',
    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/cancel' },
    ],
    fileGenerated: false,
  },

  {
    flowStatus: 'pending',
    flow_name: 'Flow 5: Buyer initiated Return (Full Order)',
    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/status' },
      { status: 'pending', api: '/update' },
    ],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 6: Buyer initiated Return (Partial Order)',
    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/status' },
      { status: 'pending', api: '/update' },
    ],
    fileGenerated: false,
  },

  {
    flowStatus: 'pending',
    flow_name: 'Flow 7: Out of Stock',
    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
    ],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 8: Issue and Grievance Management (IGM)',
    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/issue' },
      { status: 'pending', api: '/issue_status' },
      { status: 'pending', api: '/update' },
    ],
    fileGenerated: false,
  },

  {
    flowStatus: 'pending',
    flow_name: 'Flow 9: Non Cancellable Flow',
    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/cancel' },
    ],
    fileGenerated: false,
  },

  {
    flowStatus: 'pending',
    flow_name: 'Flow 10: Search and Custom Menu',
    order: [{ status: 'pending', api: '/search' }],
    fileGenerated: false,
    note: 'Ensure on_search functionality accommodates Non cancellable items',
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 11: Info',
    order: [{ status: 'pending', api: '/info' }],
    fileGenerated: false,
  },
  // {
  //   flowStatus: 'pending',
  //   flow_name: 'Flow 12: Self Pickup',
  //   order: [
  //     { status: 'pending', api: '/search' },
  //     { status: 'pending', api: '/select' },
  //     { status: 'pending', api: '/init' },
  //     { status: 'pending', api: '/confirm' },
  //     { status: 'pending', api: '/status' },
  //   ],
  //   fileGenerated: false,
  //   note: 'Ensure on_search functionality accommodates self-pickup items',
  // },
  // {
  //   flowStatus: 'pending',
  //   flow_name: 'Flow 13: Offers (Freebies)',
  //   order: [
  //     { status: 'pending', api: '/search' },
  //     { status: 'pending', api: '/select' },
  //     { status: 'pending', api: '/init' },
  //     { status: 'pending', api: '/confirm' },
  //     // { status: 'pending', api: '/status' },
  //   ],
  //   fileGenerated: false,
  // },
  // {
  //   flowStatus: 'pending',
  //   flow_name: 'Flow 14: Offers (BuyX GetY)',
  //   order: [
  //     { status: 'pending', api: '/search' },
  //     { status: 'pending', api: '/select' },
  //     { status: 'pending', api: '/init' },
  //     { status: 'pending', api: '/confirm' },
  //     // { status: 'pending', api: '/status' },
  //   ],
  //   fileGenerated: false,
  // },
  // {
  //   flowStatus: 'pending',
  //   flow_name: 'Flow 15: Rating',
  //   order: [
  //     { status: 'pending', api: '/search' },
  //     { status: 'pending', api: '/select' },
  //     { status: 'pending', api: '/init' },
  //     { status: 'pending', api: '/confirm' },
  //     { status: 'pending', api: '/status' },
  //     { status: 'pending', api: '/rating' },
  //   ],
  //   fileGenerated: false,
  // },
  // {
  //   flowStatus: 'pending',
  //   flow_name: 'payment-on-delivery(COD)',
  //   order: [
  //     { status: 'pending', api: '/search' },
  //     { status: 'pending', api: '/select' },
  //     { status: 'pending', api: '/init' },
  //     { status: 'pending', api: '/confirm' },
  //     { status: 'pending', api: '/status' },
  //   ],
  //   fileGenerated: false,
  // },

  // {
  //   flowStatus: 'pending',
  //   flow_name: 'complaint-resolution-item(IGM)-cancellation',
  //   order: [
  //     { status: 'pending', api: '/search' },
  //     { status: 'pending', api: '/select' },
  //     { status: 'pending', api: '/init' },
  //     { status: 'pending', api: '/confirm' },
  //     { status: 'pending', api: '/issue' },
  //     { status: 'pending', api: '/issue_status' },
  //     { status: 'pending', api: '/cancel' },
  //   ],
  //   fileGenerated: false,
  // },
  // {
  //   flowStatus: 'pending',
  //   flow_name: 'unacceptable-buyer-finder-fee',
  //   order: [{ status: 'pending', api: '/search' }],
  //   fileGenerated: false,
  // },
  // {
  //   flowStatus: 'pending',
  //   flow_name: 'downloadable-catalog-zip',
  //   order: [{ status: 'pending', api: '/search' }],
  //   fileGenerated: false,
  // },

  // {
  //   flowStatus: 'pending',
  //   flow_name: 'catalog-rejection',
  //   order: [
  //     { status: 'pending', api: '/search' },
  //     { status: 'pending', api: ' /catalog_error' },
  //   ],
  //   fileGenerated: false,
  // },
  // {
  //   flowStatus: 'pending',
  //   flow_name: 'payment-collection-SNP',
  //   order: [
  //     { status: 'pending', api: '/search' },
  //     { status: 'pending', api: '/select' },
  //     { status: 'pending', api: '/init' },
  //     { status: 'pending', api: '/confirm' },
  //   ],
  //   fileGenerated: false,
  // },
]

export const defaultSellerProgressFlows = [
  {
    flow_name: 'Flow 1: Order to confirm to fulfillment',
    status: 'pending',
    order: ['/search', '/select', '/init', '/confirm', '/status', '/track', '/status'],
  },
  {
    flow_name: 'Flow 2: Buyer Side Full Order Cancellation',
    status: 'pending',
    order: ['/search', '/select', '/init', '/confirm', '/cancel'],
  },
  {
    flow_name: 'Flow 3: Merchant Side Partial Order Cancellation',
    status: 'pending',
    order: ['/search', '/select', '/init', '/confirm', '/update'],
  },
  {
    flow_name: 'Flow 4: Merchant Side Full Order Cancellation (RTO Flow)',
    status: 'pending',
    order: ['/search', '/select', '/init', '/confirm', '/status', '/cancel', '/update', '/status'],
  },
  {
    flow_name: 'Flow 5: Merchant Side Full Order Cancellation',
    status: 'pending',
    order: ['/search', '/select', '/init', '/confirm', '/cancel'],
  },
  {
    flow_name: 'Flow 5: Buyer initiated Return (Full Order)',
    status: 'pending',
    order: ['/search', '/select', '/init', '/confirm', '/status', '/update'],
  },
  {
    flow_name: 'Flow 6: Buyer initiated Return (Partial Order)',
    status: 'pending',
    order: ['/search', '/select', '/init', '/confirm', '/status', '/update'],
  },
  {
    flow_name: 'Flow 7: Out of Stock',
    status: 'pending',
    order: ['/search', '/select'],
  },
  {
    flow_name: 'Flow 8: Issue and Grievance Management (IGM)',
    status: 'pending',
    order: ['/search', '/select', '/init', '/confirm', '/issue', '/issue_status', '/update'],
  },
  {
    flow_name: 'Flow 9: Non Cancellable Flow',
    status: 'pending',
    order: ['/search', '/select', '/init', '/confirm', '/cancel'],
    note: 'Ensure on_search functionality accommodates Non cancellable items',
  },

  {
    flow_name: 'Flow 10: Search and Custom Menu',
    status: 'pending',
    order: ['/search'],
  },
  {
    flow_name: 'Flow 11: Info',
    status: 'pending',
    order: ['/info'],
  },
  // {
  //   flow_name: 'Flow 12: Self Pickup',
  //   status: 'pending',
  //   order: ['/search', '/select', '/init', '/confirm', '/status'],
  //   note: 'Ensure on_search functionality accommodates self-pickup items',
  // },
  // {
  //   flow_name: 'Flow 13: Offers (Freebies)',
  //   status: 'pending',
  //   order: ['/search', '/select', '/init', '/confirm'],
  // },
  // {
  //   flow_name: 'Flow 14: Offers (BuyX GetY)',
  //   status: 'pending',
  //   order: ['/search', '/select', '/init', '/confirm'],
  // },
  // {
  //   flow_name: 'Flow 15: Rating',
  //   status: 'pending',
  //   order: ['/search', '/select', '/init', '/confirm', '/status', '/rating'],
  // },

  // {
  //   flow_name: 'payment-on-delivery(COD)',
  //   status: 'pending',
  //   order: ['/search', '/select', '/init', '/confirm', '/status'],
  // },
  // {
  //   flow_name: 'complaint-resolution-item(IGM)-cancellation',
  //   status: 'pending',
  //   order: ['/search', '/select', '/init', '/confirm', '/issue', '/issue_status', '/cancel'],
  // },
  // {
  //   flow_name: 'unacceptable-buyer-finder-fee',
  //   status: 'pending',
  //   order: ['/search'],
  // },
  // {
  //   flow_name: 'downloadable-catalog-zip',
  //   status: 'pending',
  //   order: ['/search'],
  // },

  // {
  //   flow_name: 'catalog-rejection',
  //   status: 'pending',
  //   order: ['/search', '/catalog_error'],
  // },
  // {
  //   flow_name: 'payment-collection-SNP',
  //   status: 'pending',
  //   order: ['/search', '/select', '/init', '/confirm'],
  // },
]
export const sellerProgressFlowsOnNetwork = [
  {
    flowStatus: 'pending',
    flow_name: 'Flow 1: Order to confirm to fulfillment',
    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/status' },
      { status: 'pending', api: '/track' },
      { status: 'pending', api: '/status' },
    ],
    // logisticsOrder: ['/search', '/init', '/confirm', '/status', '/track', '/status'],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 2: Buyer Side Full Order Cancellation',
    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/cancel' },
    ],
    // logisticsOrder: [['/search', '/init', '/confirm', '/cancel']],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 3: Merchant Side Partial Order Cancellation',
    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/update' },
    ],
    // logisticsOrder: ['/search', '/init', '/confirm', '/update'],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 4: Merchant Side Full Order Cancellation (RTO Flow)',
    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/status' },
      { status: 'pending', api: '/cancel' },
      { status: 'pending', api: '/status' },
    ],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 5: Merchant Side Full Order Cancellation',
    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/cancel' },
    ],
    // logisticsOrder: [['/search', '/init', '/confirm', '/status', '/cancel', '/status']],
    fileGenerated: false,
  },

  {
    flowStatus: 'pending',
    flow_name: 'Flow 5: Buyer initiated Return (Full Order)',
    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/status' },
      { status: 'pending', api: '/update' },
    ],
    // logisticsOrder: ['/search', '/init', '/confirm', '/status', '/update'],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 6: Buyer initiated Return (Partial Order)',
    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/status' },
      { status: 'pending', api: '/update' },
    ],

    // logisticsOrder: ['/search', '/init', '/confirm', '/status', '/update'],
    fileGenerated: false,
  },

  {
    flowStatus: 'pending',
    flow_name: 'Flow 7: Out of Stock',
    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
    ],
    // logisticsOrder: ['/search'],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 8: Issue and Grievance Management (IGM)',

    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/issue' },
      { status: 'pending', api: '/issue_status' },
      { status: 'pending', api: '/update' },
    ],
    // logisticsOrder: ['/search', '/init', '/confirm', '/issue', '/issue_status', '/update'],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 9: Non Cancellable Flow',
    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/cancel' },
    ],
    // logisticsOrder: ['/search', '/init', '/confirm', '/cancel'],
    fileGenerated: false,
  },

  {
    flowStatus: 'pending',
    flow_name: 'Flow 10: Search and Custom Menu',
    order: [{ status: 'pending', api: '/search' }],
    // logisticsOrder: ['not required'],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 11: Info',
    order: [{ status: 'pending', api: '/info' }],
    fileGenerated: false,
  },
  // {
  //   flowStatus: 'pending',
  //   flow_name: 'Flow 12: Self Pickup',
  //   order: [
  //     { status: 'pending', api: '/search' },
  //     { status: 'pending', api: '/select' },
  //     { status: 'pending', api: '/init' },
  //     { status: 'pending', api: '/confirm' },
  //     { status: 'pending', api: '/status' },
  //   ],
  //   // logisticsOrder: ['/search', '/init', '/confirm', '/status'],
  //   note: 'Ensure on_search functionality accommodates self-pickup items',
  // },
  // {
  //   flowStatus: 'pending',
  //   flow_name: 'Flow 13: Offers (Freebies)',
  //   order: [
  //     { status: 'pending', api: '/search' },
  //     { status: 'pending', api: '/select' },
  //     { status: 'pending', api: '/init' },
  //     { status: 'pending', api: '/confirm' },
  //     // { status: 'pending', api: '/status' },
  //   ],
  //   // logisticsOrder: ['/search', '/init', '/confirm', '/status'],
  //   fileGenerated: false,
  // },
  // {
  //   flowStatus: 'pending',
  //   flow_name: 'Flow 14: Offers (BuyX GetY)',
  //   order: [
  //     { status: 'pending', api: '/search' },
  //     { status: 'pending', api: '/select' },
  //     { status: 'pending', api: '/init' },
  //     { status: 'pending', api: '/confirm' },
  //     // { status: 'pending', api: '/status' },
  //   ],
  //   // logisticsOrder: ['/search', '/init', '/confirm', '/status'],
  //   fileGenerated: false,
  // },
  // {
  //   flowStatus: 'pending',
  //   flow_name: 'Flow 15: Rating',
  //   order: [
  //     { status: 'pending', api: '/search' },
  //     { status: 'pending', api: '/select' },
  //     { status: 'pending', api: '/init' },
  //     { status: 'pending', api: '/confirm' },
  //     { status: 'pending', api: '/status' },
  //     { status: 'pending', api: '/rating' },
  //   ],
  //   fileGenerated: false,
  // },

  {
    flowStatus: 'pending',
    flow_name: 'Flow A1: Order to confirm to fulfillment (On-Network Logistics)',
    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/status' },
      { status: 'pending', api: '/track' },
      { status: 'pending', api: '/status' },
    ],
    logisticsOrder: ['/search', '/init', '/confirm', '/status', '/track', '/status'],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow A2: Merchant Side Full Order Cancellation RTO Flow (On-Network Logistics)',
    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/status' },
      { status: 'pending', api: '/cancel' },
      { status: 'pending', api: '/status' },
    ],
    logisticsOrder: ['/search', '/init', '/confirm', '/status', '/cancel', '/status'],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow A3: Issue and Grievance Management (IGM) - Cascaded Flow',

    order: [
      { status: 'pending', api: '/search' },
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/issue' },
      { status: 'pending', api: '/issue_status' },
    ],

    logisticsOrder: ['/search', '/init', '/confirm', '/issue', '/issue_status'],
    fileGenerated: false,
  },
  // {
  //   flowStatus: 'pending',
  //   flow_name: 'Flow A4: Rating (Cascaded Flow)',
  //   order: [
  //     { status: 'pending', api: '/search' },
  //     { status: 'pending', api: '/select' },
  //     { status: 'pending', api: '/init' },
  //     { status: 'pending', api: '/confirm' },
  //     { status: 'pending', api: '/status' },
  //     { status: 'pending', api: '/rating' },
  //   ],
  //   logisticsOrder: ['/search', '/init', '/confirm', '/status', '/rating'],
  //   fileGenerated: false,
  // },
  // {
  //   flowStatus: 'pending',
  //   flow_name: 'fulfillment-delay-on-network',
  //   order: [
  //     { status: 'pending', api: '/search' },
  //     { status: 'pending', api: '/select' },
  //     { status: 'pending', api: '/init' },
  //     { status: 'pending', api: '/confirm' },
  //     { status: 'pending', api: '/status' },
  //   ],
  //   logisticsOrder: ['/search', '/init', '/confirm', '/status'],
  //   fileGenerated: false,
  // },
  // {
  //   flowStatus: 'pending',
  //   flow_name: 'payment-collection-SNP',
  //   order: [
  //     { status: 'pending', api: '/search' },
  //     { status: 'pending', api: '/select' },
  //     { status: 'pending', api: '/init' },
  //     { status: 'pending', api: '/confirm' },
  //   ],
  //   fileGenerated: false,
  // },
  // {
  //   flowStatus: 'pending',
  //   flow_name: 'catalog-rejection',
  //   order: [
  //     { status: 'pending', api: '/search' },
  //     { status: 'pending', api: ' /catalog_error' },
  //   ],
  //   fileGenerated: false,
  // },
]

export const defaultSellerProgressFlowsOnNetwork = [
  {
    flow_name: 'Flow 1: Order to confirm to fulfillment',
    status: 'pending',
    order: ['/search', '/select', '/init', '/confirm', '/status', '/track', '/status'],
  },
  {
    flow_name: 'Flow 2: Buyer Side Full Order Cancellation',
    status: 'pending',
    order: ['/search', '/select', '/init', '/confirm', '/cancel'],
  },
  {
    flow_name: 'Flow 3: Merchant Side Partial Order Cancellation',
    status: 'pending',
    order: ['/search', '/select', '/init', '/confirm', '/update'],
  },
  {
    flow_name: 'Flow 4: Merchant Side Full Order Cancellation (RTO Flow)',
    status: 'pending',
    order: ['/search', '/select', '/init', '/confirm', '/status', '/cancel', '/status'],
  },
  {
    flow_name: 'Flow 5: Merchant Side Full Order Cancellation',
    status: 'pending',
    order: ['/search', '/select', '/init', '/confirm', '/cancel'],
  },
  {
    flow_name: 'Flow 5: Buyer initiated Return (Full Order)',
    status: 'pending',
    order: ['/search', '/select', '/init', '/confirm', '/status', '/update'],
  },
  {
    flow_name: 'Flow 6: Buyer initiated Return (Partial Order)',
    status: 'pending',
    order: ['/search', '/select', '/init', '/confirm', '/status', '/update'],
  },
  {
    flow_name: 'Flow 7: Out of Stock',
    status: 'pending',
    order: ['/search', '/select'],
  },
  {
    flow_name: 'Flow 8: Issue and Grievance Management (IGM)',
    status: 'pending',
    order: ['/search', '/select', '/init', '/confirm', '/issue', '/issue_status', '/update'],
  },
  {
    flow_name: 'Flow 9: Non Cancellable Flow',
    status: 'pending',
    order: ['/search', '/select', '/init', '/confirm', '/cancel'],
    note: 'Ensure on_search functionality accommodates Non cancellable items',
  },

  {
    flow_name: 'Flow 10: Search and Custom Menu',
    status: 'pending',
    order: ['/search'],
  },
  {
    flow_name: 'Flow 11: Info',
    status: 'pending',
    order: ['/info'],
  },
  // {
  //   flow_name: 'Flow 12: Self Pickup',
  //   status: 'pending',
  //   order: ['/search', '/select', '/init', '/confirm', '/status'],
  //   note: 'Ensure on_search functionality accommodates self-pickup items',
  // },
  // {
  //   flow_name: 'Flow 13: Offers (Freebies)',
  //   status: 'pending',
  //   order: ['/search', '/select', '/init', '/confirm'],
  // },
  // {
  //   flow_name: 'Flow 14: Offers (BuyX GetY)',
  //   status: 'pending',
  //   order: ['/search', '/select', '/init', '/confirm'],
  // },
  // {
  //   flow_name: 'Flow 15: Rating',
  //   status: 'pending',
  //   order: ['/search', '/select', '/init', '/confirm', '/status', '/rating'],
  // },

  {
    flow_name: 'Flow A1: Order to confirm to fulfillment (On-Network Logistics)',
    status: 'pending',
    order: ['/search', '/select', '/init', '/confirm', '/status', '/track', '/status'],
    logisticsOrder: ['/search', '/init', '/confirm', '/status', '/track', '/status'],
  },
  {
    flow_name: 'Flow A2: Merchant Side Full Order Cancellation RTO Flow (On-Network Logistics)',
    status: 'pending',
    order: ['/search', '/select', '/init', '/confirm', '/status', '/cancel', '/status'],
    logisticsOrder: ['/search', '/init', '/confirm', '/status', '/cancel', '/status'],
  },
  {
    flow_name: 'Flow A3: Issue and Grievance Management (IGM) - Cascaded Flow',
    status: 'pending',
    order: ['/search', '/select', '/init', '/confirm', '/issue', '/issue_status'],
    logisticsOrder: ['/search', '/init', '/confirm', '/issue', '/issue_status'],
  },

  // {
  //   flow_name: 'Flow A4: Rating (Cascaded Flow)',
  //   status: 'pending',
  //   order: ['/search', '/select', '/init', '/confirm', '/status', '/rating'],
  //   logisticsOrder: ['/search', '/init', '/confirm', '/status', '/rating'],
  // },
  // {
  //   flow_name: 'catalog-rejection',
  //   status: 'pending',
  //   order: ['/search', '/catalog_error'],
  // },
  // {
  //   flow_name: 'fulfillment-delay-on-network',
  //   status: 'pending',
  //   order: ['/search', '/select', '/init', '/confirm'],
  //   logisticsOrder: ['/search', '/init', '/confirm', '/status'],
  // },
  // {
  //   flow_name: 'payment-collection-SNP',
  //   status: 'pending',
  //   order: ['/search', '/select', '/init', '/confirm'],
  // },
]

export const buyerProgressFlows = [
  // {
  //   flowStatus: 'pending',
  //   flow_name: 'order-fulfillment-buyer-delivery',
  //   order: [
  //     { status: 'pending', api: '/select' },
  //     { status: 'pending', api: '/init' },
  //     { status: 'pending', api: '/confirm' },
  //     { status: 'pending', api: '/status' },
  //     { status: 'pending', api: '/update' },
  //   ],
  //   fileGenerated: false,
  // },
  // {
  //   flowStatus: 'pending',
  //   flow_name: 'order-fulfillment-buyer-delivery-RTO',
  //   order: [
  //     { status: 'pending', api: '/select' },
  //     { status: 'pending', api: '/init' },
  //     { status: 'pending', api: '/confirm' },
  //     { status: 'pending', api: '/status' },
  //   ],
  //   fileGenerated: false,
  // },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 1: Order to confirm to fulfillment with multiple items order',
    order: [
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/status' },
      { status: 'pending', api: '/track' },
      { status: 'pending', api: '/status' },
    ],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 2: Buyer Side Full Order Cancellation',
    order: [
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/cancel' },
    ],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 3: Merchant Side Partial Order Cancellation',
    order: [
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/update' },
    ],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 4: Merchant Side Full Order Cancellation (RTO Flow)',
    order: [
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/status' },
      { status: 'pending', api: '/cancel' },
      { status: 'pending', api: '/status' },
      { status: 'pending', api: '/update' },
    ],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 5: Buyer initiated Return',
    order: [
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/status' },
      { status: 'pending', api: '/update' },
    ],
    fileGenerated: false,
  },

  {
    flowStatus: 'pending',
    flow_name: 'Flow 6: Search, Incremental Catalog Search and Custom Menu',
    order: [
      { status: 'pending', api: '/search_by_city' },
      { status: 'pending', api: '/search_by_item' },
      { status: 'pending', api: '/search_by_category' },
      { status: 'pending', api: '/search_by_fulfillment_end_location' },
      { status: 'pending', api: '/search_inc_catalog_start' },
      { status: 'pending', api: '/search_inc_catalog_stop' },
      { status: 'pending', api: '/search_inc_catalog_one_time' },
      { status: 'pending', api: '/select' },
    ],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 7: Issue and Grievance Management (IGM)',
    order: [
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/issue' },
      { status: 'pending', api: '/issue_status' },
      { status: 'pending', api: '/issue' },
    ],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 8: Customization and Variants',
    order: [
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
    ],
    fileGenerated: false,
  },

  {
    flowStatus: 'pending',
    flow_name: 'Flow 9: Info',
    order: [{ status: 'pending', api: '/info' }],
    fileGenerated: false,
  },
  // {
  //   flowStatus: 'pending',
  //   flow_name: 'Flow 10: Rating',
  //   order: [
  //     { status: 'pending', api: '/search' },
  //     { status: 'pending', api: '/select' },
  //     { status: 'pending', api: '/init' },
  //     { status: 'pending', api: '/confirm' },
  //     { status: 'pending', api: '/status' },
  //     { status: 'pending', api: '/rating' },
  //   ],
  //   fileGenerated: false,
  // },
]
export const onNetworkBuyerProgress = [
  {
    flowStatus: 'pending',
    flow_name: 'order-fulfillment-buyer-delivery',
    order: [
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/status' },
      // { status: 'pending', api: '/update' },
    ],
    logisticsOrder: ['/search', '/init', '/confirm', '/status'],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'order-fulfillment-buyer-delivery-RTO',
    order: [
      { status: 'pending', api: '/select' },
      { status: 'pending', api: '/init' },
      { status: 'pending', api: '/confirm' },
      { status: 'pending', api: '/status' },
      // { status: 'pending', api: '/update' },
    ],
    logisticsOrder: ['/search', '/init', '/confirm', '/cancel', '/status'],
    fileGenerated: false,
  },
]
export const buyerPostFlow = [
  // {
  //   flow_name: 'order-fulfillment-buyer-delivery',
  //   order: ['/select', '/init', '/confirm', '/status', '/update'],
  // },
  // {
  //   flow_name: 'order-fulfillment-buyer-delivery-RTO',
  //   order: ['/select', '/init', '/confirm', '/status'],
  // },
  {
    flow_name: 'Flow 1: Order to confirm to fulfillment with multiple items order',
    order: ['/select', '/init', '/confirm', '/status', '/track', '/status'],
  },
  {
    flow_name: 'Flow 2: Buyer Side Full Order Cancellation',
    order: ['/select', '/init', '/confirm', '/cancel'],
  },
  {
    flow_name: 'Flow 3: Merchant Side Partial Order Cancellation',
    order: ['/select', '/init', '/confirm', '/update'],
  },
  {
    flow_name: 'Flow 4: Merchant Side Full Order Cancellation (RTO Flow)',
    order: ['/select', '/init', '/confirm', '/status', '/cancel', '/status', '/update'],
  },
  {
    flow_name: 'Flow 5: Buyer initiated Return',
    order: ['/select', '/init', '/confirm', '/status', '/update'],
  },
  {
    flow_name: 'Flow 6: Search, Incremental Catalog Search and Custom Menu',
    order: ['/search', '/select'],
  },
  {
    flow_name: 'Flow 7: Issue and Grievance Management (IGM)',
    order: ['/select', '/init', '/confirm', '/issue', '/issue_status', '/issue'],
  },
  {
    flow_name: 'Flow 8: Customization and Variants',
    order: ['/select', '/init', '/confirm'],
  },

  {
    flow_name: 'Flow 9: Info',
    order: ['/info'],
  },
  // {
  //   flow_name: 'Flow 10: Rating',
  //   order: ['/search', '/select', '/init', '/confirm', '/status', '/rating'],
  // },
]

export const onNetworkBuyerPostFlows = [
  {
    flow_name: 'order-fulfillment-buyer-delivery',
    order: ['/select', '/init', '/confirm', '/status'],
    logistics_order: ['/search', '/init', '/confirm', '/status'],
    // order: ['/select', '/init', '/confirm', '/status', '/update'],
  },
  {
    flow_name: 'order-fulfillment-buyer-delivery-RTO',
    order: ['/select', '/init', '/confirm', '/status'],
    logistics_order: ['/search', '/init', '/confirm', '/cancel', '/status'],
  },
]

export const logisticsProgressFlows = [
  {
    flowStatus: 'pending',
    flow_name: 'Flow 1A: Order to confirm to fulfillment - Standard Delivery',
    order: [
      { api: '/search' },
      { api: '/init' },
      { api: '/confirm' },
      { api: '/status' },
      { api: '/track' },
      { api: '/status' },
    ],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 1B: Order to confirm to fulfillment - Same Day Delivery',
    order: [
      { api: '/search' },
      { api: '/init' },
      { api: '/confirm' },
      { api: '/status' },
      { api: '/track' },
      { api: '/status' },
    ],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 1C: Order to confirm to fulfillment - Next Day Delivery',
    order: [
      { api: '/search' },
      { api: '/init' },
      { api: '/confirm' },
      { api: '/status' },
      { api: '/track' },
      { api: '/status' },
    ],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 1D: Order to confirm to fulfillment - Immediate Delivery',
    order: [
      { api: '/search' },
      { api: '/init' },
      { api: '/confirm' },
      { api: '/status' },
      { api: '/track' },
      { api: '/status' },
    ],
    fileGenerated: false,
  },

  {
    flowStatus: 'pending',
    flow_name: 'Flow 1E: Order to confirm to fulfillment - Express Delivery',
    order: [
      { api: '/search' },
      { api: '/init' },
      { api: '/confirm' },
      { api: '/status' },
      { api: '/track' },
      { api: '/status' },
    ],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 2: Order to confirm to fulfillment (Deferred RTS Flow)',
    order: [
      { api: '/search' },
      { api: '/init' },
      { api: '/confirm' },
      // { api: '/status' },
      { api: '/update' },
      { api: '/status' },
    ],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 3: RTO Flow (Return Back to Seller)',
    order: [
      { api: '/search' },
      { api: '/init' },
      { api: '/confirm' },
      { api: '/status' },
      { api: '/cancel' },
      { api: '/status' },
    ],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 4: RTO Flow (Liquidation)',
    order: [
      { api: '/search' },
      { api: '/init' },
      { api: '/confirm' },
      { api: '/status' },
      { api: '/cancel' },
      { api: '/status' },
    ],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 5: Merchant Side Full order Cancellation',
    order: [{ api: '/search' }, { api: '/init' }, { api: '/confirm' }, { api: '/status' }, { api: '/cancel' }],
    fileGenerated: false,
  },
  {
    flowStatus: 'pending',
    flow_name: 'Flow 6: Issue and Grievance Management (IGM)',
    order: [
      { api: '/search' },
      { api: '/init' },
      { api: '/confirm' },
      { api: '/status' },
      { api: '/issue' },
      { api: '/issue_status' },
    ],
    fileGenerated: false,
  },
  // {
  //   flowStatus: 'pending',
  //   flow_name: 'Flow 7: Rating',
  //   order: [
  //     { status: 'pending', api: '/search' },
  //     { status: 'pending', api: '/select' },
  //     { status: 'pending', api: '/init' },
  //     { status: 'pending', api: '/confirm' },
  //     { status: 'pending', api: '/status' },
  //     { status: 'pending', api: '/rating' },
  //   ],
  //   fileGenerated: false,
  // },
]
export const logisticsPostFlow = [
  {
    flow_name: 'Flow 1A: Order to confirm to fulfillment - Standard Delivery',
    order: ['/search', '/init', '/confirm', '/status', '/track', '/status'],
  },
  {
    flow_name: 'Flow 1B: Order to confirm to fulfillment - Same Day Delivery',
    order: ['/search', '/init', '/confirm', '/status', '/track', '/status'],
  },
  {
    flow_name: 'Flow 1C: Order to confirm to fulfillment - Next Day Delivery',
    order: ['/search', '/init', '/confirm', '/status', '/track', '/status'],
  },
  {
    flow_name: 'Flow 1D: Order to confirm to fulfillment - Immediate Delivery',
    order: ['/search', '/init', '/confirm', '/status', '/track', '/status'],
  },
  {
    flow_name: 'Flow 1E: Order to confirm to fulfillment - Express Delivery',
    order: ['/search', '/init', '/confirm', '/status', '/track', '/status'],
  },
  {
    flow_name: 'Flow 2: Order to confirm to fulfillment (Deferred RTS Flow)',
    order: ['/search', '/init', '/confirm', '/update', '/status'],
  },
  {
    flow_name: 'Flow 3: RTO Flow (Return Back to Seller)',
    order: ['/search', '/init', '/confirm', '/status', '/cancel', '/status'],
  },
  {
    flow_name: 'Flow 4: RTO Flow (Liquidation)',
    order: ['/search', '/init', '/confirm', '/status', '/cancel', '/status'],
  },
  {
    flow_name: 'Flow 5: Merchant Side Full order Cancellation',
    order: ['/search', '/init', '/confirm', '/status', '/cancel'],
  },

  {
    flow_name: 'Flow 6: Issue and Grievance Management (IGM)',
    order: ['/search', '/init', '/confirm', '/status', '/issue', '/issue_status'],
  },
  // {
  //   flow_name: 'Flow 7: Rating',
  //   status: 'pending',
  //   order: ['/search', '/select', '/init', '/confirm', '/status', '/rating'],
  // },
]

export const logisticsBuyerPostFlows = [
  {
    flow_name: 'Flow 1A: Order to confirm to fulfillment',
    order: ['/search', '/init', '/confirm', '/status', '/track', '/status'],
  },
  {
    flow_name: 'Flow 1B: Order to confirm to fulfillment (Deferred RTS Flow)',
    order: ['/search', '/init', '/confirm', '/update', '/status'],
  },
  {
    flow_name: 'Flow 2A: RTO Flow (Return Back to Seller)',
    order: ['/search', '/init', '/confirm', '/status', '/cancel', '/status'],
  },
  {
    flow_name: 'Flow 2B: RTO Flow (Liquidation)',
    order: ['/search', '/init', '/confirm', '/status', '/cancel', '/status'],
  },
  {
    flow_name: 'Flow 3: Buyer Side Full Order Cancellation',
    order: ['/search', '/init', '/confirm', '/cancel'],
  },
  {
    flow_name: 'Flow 4: Merchant Side Full order Cancellation',
    order: ['/search', '/init', '/confirm', '/status', '/cancel'],
  },
  {
    flow_name: 'Flow 5: Issue and Grievance Management (IGM)',
    order: ['/search', '/init', '/confirm', '/status', '/issue', '/issue_status'],
  },
  // {
  //   flow_name: 'Flow 6: Rating',
  //   order: ['/select', '/init', '/confirm'],
  // },
]
export const logisticsBuyerProgressFlows = [
  {
    flow_name: 'Flow 1A: Order to confirm to fulfillment',
    flowStatus: 'pending',
    order: [
      { api: '/search' },
      { api: '/init' },
      { api: '/confirm' },
      { api: '/status' },
      { api: '/track' },
      { api: '/status' },
    ],
    fileGenerated: false,
  },
  {
    flow_name: 'Flow 1B: Order to confirm to fulfillment (Deferred RTS Flow)',
    flowStatus: 'pending',
    order: [{ api: '/search' }, { api: '/init' }, { api: '/confirm' }, { api: '/update' }, { api: '/status' }],
    fileGenerated: false,
  },
  {
    flow_name: 'Flow 2A: RTO Flow (Return Back to Seller)',
    flowStatus: 'pending',
    order: [
      { api: '/search' },
      { api: '/init' },
      { api: '/confirm' },
      { api: '/status' },
      { api: '/cancel' },
      { api: '/status' },
    ],
    fileGenerated: false,
  },
  {
    flow_name: 'Flow 2B: RTO Flow (Liquidation)',
    flowStatus: 'pending',
    order: [
      { api: '/search' },
      { api: '/init' },
      { api: '/confirm' },
      { api: '/status' },
      { api: '/cancel' },
      { api: '/status' },
    ],
    fileGenerated: false,
  },
  {
    flow_name: 'Flow 3: Buyer Side Full Order Cancellation',
    flowStatus: 'pending',
    order: [{ api: '/search' }, { api: '/init' }, { api: '/confirm' }, { api: '/cancel' }],
    fileGenerated: false,
  },

  {
    flow_name: 'Flow 4: Merchant Side Full order Cancellation',
    flowStatus: 'pending',
    order: [{ api: '/search' }, { api: '/init' }, { api: '/confirm' }, { api: '/status' }, { api: '/cancel' }],
    fileGenerated: false,
  },
  {
    flow_name: 'Flow 5: Issue and Grievance Management (IGM)',
    flowStatus: 'pending',
    order: [
      { api: '/search' },
      { api: '/init' },
      { api: '/confirm' },
      { api: '/status' },
      { api: '/issue' },
      { api: '/issue_status' },
    ],
    fileGenerated: false,
  },
  // {
  //   flow_name: 'Flow 6: Rating',
  //   flowStatus: 'pending',
  //   order: [{ api: '/search' }, { api: '/init' }, { api: '/confirm' }],
  //   fileGenerated: false,
  // },
]

export const customFlow = {
  name: 'customization-and-variants',
  order: ['/search', '/select', '/init', '/confirm'],
  status: 'pending',
}

// export const msnFlow = {
//   name: 'order-confirmation-msn',
//   status: 'pending',
//   order: ['/search', '/select', '/init', '/confirm'],
// }
