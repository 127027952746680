import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { v4 as uuid } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { Button, notification } from 'antd'
import { PayloadContext } from 'context'
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
import usePost from 'hooks/usePost'
import APIS from 'constants/api'
import { LoaderContext } from 'context/loader'
import { logisticsPostFlow, logisticsProgressFlows } from 'constants/constants'
import Watermark from 'assets/svg/watermark'
import Navbar from 'components/Navbar'
import CustomButton from 'components/Button'
import Loader from 'components/Loader'
import Stepper from 'components/Stepper'
import Footer from 'components/Footer'
import { updateLogisticsReport } from 'store/logisticsDataSlice'
import {
  setLogisticsFlow,
  updateLogisticsFlowFileGenerated,
  updateLogisticsFlowStatus,
  updateLogisticsOrderStatus,
} from 'store/logisticsFlowSlice'

import {
  LoaderContainer,
  Container,
  FeedbackWrapper,
  Heading,
  HeadingWrapper,
  LeftContainer,
  LeftWrapper,
  Name,
  NameWrapper,
  RightContainer,
  RightWrapper,
  StepsWrapper,
  Wrapper,
  Header,
  Title,
  EntireFlowContainer,
  FlowHeading,
  FlowWrapper,
  RightSection,
  ButtonWrapper,
} from 'styles/pages/logisticsProgress'
import {
  ApiWrapper,
  Apis,
  Flowwrapper,
  OrderNameWrapper,
  OrderWrapper,
  ReportButtonWrapper,
} from 'styles/pages/buyerFlow'
import { API, Flow, IlogisticsPostFlow, MockFlow } from 'interface'
type NotificationType = 'success' | 'info' | 'warning' | 'error'
const LogisticsProgress = () => {
  const [content, setContent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isWaiting, setIsWaiting] = useState(false)
  const [rating, setRating] = useState(false)
  const [issueStatus, setIssueStatus] = useState(false)
  const [duration, setDuration] = useState('')
  const [cancel, setCancel] = useState(false)
  const [isComplain, setIsComplain] = useState(false)
  const [flowLoading, setFlowLoading] = useState(false)
  const [issueId, setIssueId] = useState('')
  const [transactionId, setTransactionId] = useState('')
  const [transactionBtn, setTransactionBtn] = useState(false)
  const [issueBtn, setIssueBtn] = useState(false)
  const [next, setNext] = useState(false)
  const { mutateAsync } = usePost()
  const { setLoader } = useContext(LoaderContext)
  const { payloadData } = useContext(PayloadContext)
  const logisticsDataState = useSelector((state: any) => state?.logisticsData)
  const { testId, htmlResponse } = logisticsDataState
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const logisticsFLowState = useSelector((state: any) => state.logisticsFlow)
  const homeDataState = useSelector((state: any) => state?.homeData)
  const environment = homeDataState.environment

  const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
    api[type]({
      message: message,
      description: description,
      duration: 3,
    })
  }
  const [api] = notification.useNotification()

  const updateReduxFlowStatus1 = (event: any) => {
    const { flow_name, status } = event
    dispatch(updateLogisticsOrderStatus({ flowName: flow_name, status }))
  }
  const updateReduxFlowStatus = (event: any) => {
    const { flow_name, message } = event
    dispatch(updateLogisticsFlowStatus({ flowName: flow_name, message }))
  }

  const handleFileGenerateEvent = (event: any) => {
    const { flow_name } = event
    dispatch(updateLogisticsFlowFileGenerated({ flowName: flow_name, fileGenerated: true }))
  }
  const updateFlowStatus = (input: {
    flow_name: string
    status: string
    orderTimer: string
    transactionId: string
    issueId: string
    action: string
    time: string
  }) => {
    if (input?.flow_name) setIsLoading(false)
    setDuration(input?.time)
    if (input?.orderTimer === 'start') setIsWaiting(true)
    if (input?.orderTimer === 'stop') setIsWaiting(false)
    if (input?.orderTimer === 'start' && input?.action === '/cancel') setCancel(true)
    if (input?.orderTimer === 'stop' && input?.action === '/cancel') setCancel(false)
    setTransactionId(input?.transactionId)
    setTransactionBtn(true)
    // /rating
    if (input?.action === '/rating' && input?.orderTimer === 'start') {
      setRating(true)
      setTransactionId(input?.transactionId)
    }

    if (input?.action === '/rating' && input?.orderTimer === 'stop') setRating(false)

    if (input?.flow_name === 'Flow 6: Issue & Grievance Management (IGM)') {
      setTransactionId(input?.transactionId)
      setTransactionBtn(true)
      setIssueId(input?.issueId)
      setIssueBtn(true)
      if (input?.orderTimer === 'start' && input?.action === '/issue') setIsComplain(true)
      if (input?.orderTimer === 'stop' && input?.action === '/issue') setIsComplain(false)
      if (input?.orderTimer === 'start' && input?.action === '/issue_status') setIssueStatus(true)
      if (input?.orderTimer === 'stop' && input?.action === '/issue_status') setIssueStatus(false)
    }

    dispatch(
      updateLogisticsFlowStatus({
        flowName: input?.flow_name,
        status: input?.status,
        order: ['/search', '/init', '/confirm', '/cancel'],
      }),
    )
  }

  const handleDownload = async (fileUrl: string, flow_name: string, test_id: number) => {
    try {
      const response = await axios.get(fileUrl, {
        responseType: 'blob',
      })
      const downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(new Blob([response.data]))
      downloadLink.download = flow_name + '-' + test_id + '-' + new Date().toISOString() + '.html'
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (error) {
      return error
    }
  }
  const getGeneratedFile = async (flow_name: string) => {
    const test_id = logisticsDataState?.testId
    try {
      const payload = {
        testId: testId,
        flowName: flow_name,
      }
      const response = await mutateAsync({
        url: APIS.GET_REPORT,
        payload: payload,
        mockServer: true,
      })

      dispatch(updateLogisticsReport(response.data.mesg))
      handleDownload(response.data.mesg, flow_name, test_id)
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    if (!htmlResponse) {
      setIsLoading(false)
    }

    if (!logisticsFLowState?.length) dispatch(setLogisticsFlow(logisticsProgressFlows))
    setNext(true)
  }, [])

  const connection = () => {
    setNext(true)

    const eventSource = new EventSource(`${process.env.REACT_APP_BASE_URL}/sse/${testId}`, {
      withCredentials: false,
    })

    eventSource.addEventListener('api_status', (event: MessageEvent) => {
      const data = JSON.parse(event.data)
      setLoader(false)
      updateFlowStatus(data)
      updateReduxFlowStatus1(data)
    })

    eventSource.addEventListener('file_generate', (event: MessageEvent) => {
      setLoader(false)
      setFlowLoading(false)
      const data = JSON.parse(event.data)
      if (data?.success) {
        updateReduxFlowStatus(data)
        handleFileGenerateEvent(data)
        openNotificationWithIcon('success', 'File Generated Successfully', '')
      }
    })

    return () => {
      eventSource.close()
    }
  }

  const startTest = async (flowName: any) => {
    setLoader(true)
    setFlowLoading(true)
    const pythonEnginePayload: Flow[] = []
    const mockpayload: MockFlow = {
      flows: [],
    }

    const parsedData = logisticsDataState?.testData

    const testId = logisticsDataState?.testId

    logisticsPostFlow.forEach((item: IlogisticsPostFlow) => {
      const apis: API[] = []
      if (flowName === item.flow_name) {
        item.order.forEach((item: string) => {
          const action: string = item.split('/')[1]
          const payload = payloadData[action]
          payload.context = {
            ...payload.context,
            bpp_id: parsedData.bpp_id,
            bpp_uri: parsedData.bpp_uri,
            core_version: parsedData.version,
            domain: parsedData.domain,
          }
          const obj = {
            route: item,
            payload,
          }
          apis.push(obj)
        })

        const finalObj = {
          flow_name: flowName,
          order: item.order,
          test_id: testId,
          apis,
          environment_type: environment,
        }

        pythonEnginePayload.push(finalObj)
      }

      const Payload = {
        ...parsedData.testData,
        flows: pythonEnginePayload,
        test_id: testId,
        domain: parsedData.domain,
        bpp_id: parsedData.bpp_id,
        bpp_uri: parsedData.bpp_uri,
        core_version: parsedData.version,
        np_type: parsedData.np_type,
        session_id: window.localStorage.getItem('sessionId') || '1',
        uuid: uuid(),
        location: { start: parsedData.location?.start, end: parsedData.location?.end },
        logistics_category: parsedData.logistics_category,
        environment_type: environment,
      }
      Object.assign(mockpayload, Payload)
    })

    const StartTestPayload = {
      test_id: testId,
      flow_name: flowName,
      xml_name: 'LogisticFlowTest',
    }

    await mutateAsync({
      url: APIS.POST_TEST_FLOW,
      payload: mockpayload,
      mockServer: true,
    })
    await mutateAsync({
      url: APIS.POST_FLOW,
      payload: pythonEnginePayload,
    })

    try {
      const timeout = setTimeout(() => {
        setContent(true)
      }, 120000)
      const response = await mutateAsync({
        url: APIS.POST_START_TEST,
        payload: StartTestPayload,
      })
      connection()

      if (response) {
        clearTimeout(timeout)
        setContent(false)
      }
    } catch (error) {
      return error
    }
  }

  const copyTrsactionId = transactionId
  const handleCopyClickTrans = async () => {
    try {
      await navigator.clipboard.writeText(copyTrsactionId)
    } catch (err) {
      return err
    }
  }
  const copyIssueId = issueId
  const handleCopyClickIssue = async () => {
    try {
      await navigator.clipboard.writeText(copyIssueId)
    } catch (err) {
      return err
    }
  }

  const status = logisticsFLowState.some((item: any) => item.flowStatus === 'started')

  return (
    <Wrapper>
      <Navbar />

      <Container>
        <LeftWrapper>
          <NameWrapper>
            <Name>Pramaan</Name>
          </NameWrapper>
          <LeftContainer>
            <StepsWrapper>
              <Stepper current={2} next={next} navi="/logistics-testing" />
            </StepsWrapper>
            <Watermark />
            <FeedbackWrapper>
              <CustomButton variant="text" label={'Feedback'} onClick={() => navigate('/alpha/feedback')} />
            </FeedbackWrapper>
          </LeftContainer>
        </LeftWrapper>
        <RightWrapper>
          <HeadingWrapper>
            <Heading>Execute Testing & Review Results</Heading>
          </HeadingWrapper>
          <RightContainer>
            <RightSection>
              <Header>
                <Title>Test Scenarios</Title>
              </Header>
              <FlowWrapper>
                {logisticsFLowState.map((flow: any, index: number) => {
                  return (
                    <EntireFlowContainer key={index}>
                      <Flowwrapper>
                        <FlowHeading>{flow.flow_name}</FlowHeading>
                        <ReportButtonWrapper>
                          {flow.fileGenerated ? (
                            <Button
                              type="text"
                              icon={<DownloadOutlined style={{ fontSize: '18px', color: '#21ADE8' }} />}
                              size={'middle'}
                              onClick={() => getGeneratedFile(flow.flow_name)}
                            >
                              Download Report
                            </Button>
                          ) : (
                            ''
                          )}
                        </ReportButtonWrapper>

                        <ButtonWrapper>
                          {flowLoading && flow.flowStatus == 'started' ? (
                            <Button>
                              <LoaderContainer>
                                <LoadingOutlined style={{ fontSize: '20px', color: '#666666' }} />
                                Testing
                              </LoaderContainer>
                            </Button>
                          ) : (
                            <CustomButton
                              label={'Start'}
                              variant={
                                flow.flowStatus == 'completed'
                                  ? 'disabled'
                                  : logisticsFLowState.some((item: any) => item.flowStatus === 'started')
                                  ? 'disabled'
                                  : 'contained'
                              }
                              onClick={() => startTest(flow.flow_name)}
                              disabled={flow.flowStatus == 'completed' ? true : status ? true : false}
                            />
                          )}
                        </ButtonWrapper>
                      </Flowwrapper>

                      <ApiWrapper>
                        <Apis>
                          {flow?.order?.map((it: any, ind: number) => {
                            return (
                              <OrderWrapper key={ind}>
                                <OrderNameWrapper> {it.api}</OrderNameWrapper>
                              </OrderWrapper>
                            )
                          })}
                        </Apis>
                      </ApiWrapper>
                    </EntireFlowContainer>
                  )
                })}
              </FlowWrapper>
            </RightSection>
          </RightContainer>
        </RightWrapper>
      </Container>

      {content && <Loader isModalOpen={content} text={'Please wait for 15 - 20 minutes as this test is in queue'} />}
      {isLoading && <Loader isModalOpen={true} text={'Loading'} />}
      {isWaiting && (
        <Loader
          isModalOpen={true}
          text={'Waiting '}
          subText={` Note:  Waiting ${duration} minutes for status update with `}
          subText1={`TransactionId : ${transactionId}`}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
        />
      )}
      {cancel && (
        <Loader
          isModalOpen={true}
          text={'Waiting '}
          subText={` Note:  Waiting  ${duration} minutes for on_cancel response with `}
          subText1={`TransactionId : ${transactionId}`}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
        />
      )}

      {isComplain && (
        <Loader
          isModalOpen={true}
          text={'Waiting'}
          subText={` Note: Waiting  ${duration} minutes for on_issue response with `}
          subText1={`TransactionId : ${transactionId}`}
          subText2={`IssueID : ${issueId}`}
          transactionBtn={transactionBtn}
          issueBtn={issueBtn}
          transactionBtnFn={handleCopyClickTrans}
          issueBtnFn={handleCopyClickIssue}
        />
      )}
      {issueStatus && (
        <Loader
          isModalOpen={true}
          text={'Waiting'}
          subText={` Note: Waiting  ${duration} minutes for on_issue_status response with `}
          subText1={`TransactionId : ${transactionId}`}
          subText2={`IssueID : ${issueId}`}
          transactionBtn={transactionBtn}
          issueBtn={issueBtn}
          transactionBtnFn={handleCopyClickTrans}
          issueBtnFn={handleCopyClickIssue}
        />
      )}
      {rating && (
        <Loader
          isModalOpen={true}
          text={`Waiting  ${duration} minutes for on_rating update`}
          subText1={`  TransactionId: ${transactionId}`}
          transactionBtn={transactionBtn}
          transactionBtnFn={handleCopyClickTrans}
        />
      )}
      <Footer />
    </Wrapper>
  )
}

export default LogisticsProgress
