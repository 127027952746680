import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, Input, Modal, Select } from 'antd'
import { yupResolver } from '@hookform/resolvers/yup'
import { SELLER_TEST_VALIDATION_SCHEMA } from 'validations'
import usePost from '../../hooks/usePost'
import { FormModelProps, IPayloadProps, ISellerPayload } from 'interface'
import APIS from 'constants/api'
import { SelectHits, domainSelectBox, npTypeSelectBox, versionSelectBox } from 'constants/select'
import { updateSellerOffNetworkData } from 'store/sellerDataSlice'
import { updateSellerOnNetworkData } from 'store/sellerOnNetworkDataSlice'
import CustomButton from 'components/Button'
import Loader from 'components/Loader'
import {
  ButtonWrapper,
  ErrorMessage,
  FormInnerContainer,
  FormItemWrapper,
  FormWrapper,
  HeaderWrapper,
  Label,
  ModalBody,
  ValueWrapper,
} from 'styles/components/FormModal'

export const removeFlow = (flowName: string, sellerPostFlow: any) => {
  const indexToRemove = sellerPostFlow.findIndex((flow: { name: string }) => flow.name === flowName)

  if (indexToRemove !== -1) {
    sellerPostFlow.splice(indexToRemove, 1)
  }
}

const FormModal = ({ isModalOpen, setIsModalOpen, cancelBtnLabel, confirmBtnLabel }: FormModelProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { mutateAsync, isLoading, isSuccess } = usePost()
  const [showNextSelect, setShowNextSelect] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const homeDataState = useSelector((state: any) => state?.homeData)
  const environment = homeDataState.environment
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(SELLER_TEST_VALIDATION_SCHEMA),
    defaultValues: {
      subscriber_uri: '',
      subscriber_id: '',
      serviceable_pin_code: '',
      item_name: '',
      serviceable_gps: '',
    },
  })

  const onSubmit = async (data: IPayloadProps) => {
    try {
      let metaData: {
        testData: any
        testId: string
      } = {
        testData: {},
        testId: '',
      }
      const payload: ISellerPayload = {
        domain: data.domain,
        np_type: data.np_type,
        version: data.version,
        bpp_uri: data.subscriber_uri,
        bpp_id: data.subscriber_id,
        session_id: window.localStorage.getItem('sessionId') || '1',
        serviceable_pin_code: data.serviceable_pin_code,
        serviceable_gps: data.serviceable_gps,
        item_name: data.item_name,
        provider_id: data.provider_id,
        logistics_bap_uri: data.logistics_bap_uri,
        logistics_bap_id: data.logistics_bap_id,
        type_of_hits: data.type_of_hits,
        environment_type: environment,
      }

      metaData = { ...metaData, testData: payload }

      const response: any = await mutateAsync({
        url: APIS.POST_TEST,
        payload: payload,
      })

      metaData = { ...metaData, testId: String(response?.test_id) }
      if (showNextSelect || typeof showNextSelect === 'undefined') {
        dispatch(updateSellerOnNetworkData(metaData))
        navigate('/alpha/seller-on-network-progress')
      } else {
        dispatch(updateSellerOffNetworkData(metaData))
        navigate('/alpha/seller-off-network-progress')
      }
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    if (isSuccess) {
      reset()
      setIsModalOpen(false)
    }
  }, [isSuccess])

  const handleCancel = () => {
    setIsModalOpen(false)
    reset()
  }
  const handleCheckboxChange = (event: any) => {
    const show = event.target.checked
    if (show) {
      setIsChecked(true)
      setShowNextSelect(true)
    } else {
      setIsChecked(false)
      setShowNextSelect(false)
    }
  }

  return (
    <>
      {isLoading ? (
        <Loader isModalOpen={true} text={'Generating flows'} />
      ) : (
        <Modal
          open={isModalOpen}
          onCancel={handleCancel}
          centered
          width={'572px'}
          styles={{ body: { height: '552px', color: '#606161' } }}
          title="Fill Application Details"
          footer={null}
          maskClosable={false}
        >
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormInnerContainer>
                <FormWrapper>
                  <FormItemWrapper>
                    <ValueWrapper>
                      <Label>Domain *</Label>
                      <Controller
                        name="domain"
                        control={control}
                        render={({ field }) => (
                          <Select
                            placeholder="Select Domain"
                            size="large"
                            style={{ width: '100%' }}
                            {...field}
                            options={domainSelectBox}
                          />
                        )}
                      />
                      {errors.domain && <ErrorMessage>{errors?.domain?.message}</ErrorMessage>}
                    </ValueWrapper>
                    <ValueWrapper>
                      <Label>Item Name*</Label>
                      <Controller
                        name="item_name"
                        control={control}
                        render={({ field }) => <Input {...field} placeholder="Enter Name" size="large" />}
                      />
                      {errors.item_name && <ErrorMessage>{errors?.item_name?.message}</ErrorMessage>}
                    </ValueWrapper>
                  </FormItemWrapper>
                </FormWrapper>
                <FormWrapper>
                  <FormItemWrapper>
                    <ValueWrapper>
                      <Label>Serviceable Pin Code*</Label>
                      <Controller
                        name="serviceable_pin_code"
                        control={control}
                        render={({ field }) => <Input {...field} placeholder="Enter Pincode" size="large" />}
                      />
                      {errors.serviceable_pin_code && (
                        <ErrorMessage>{errors?.serviceable_pin_code?.message}</ErrorMessage>
                      )}
                    </ValueWrapper>
                    <ValueWrapper>
                      <Label>Serviceable GPS *</Label>
                      <Controller
                        name="serviceable_gps"
                        control={control}
                        render={({ field }) => <Input {...field} placeholder="Enter Serviceable GPS " size="large" />}
                      />
                      {errors.serviceable_gps && <ErrorMessage>{errors?.serviceable_gps?.message}</ErrorMessage>}
                    </ValueWrapper>
                  </FormItemWrapper>
                </FormWrapper>
                <FormWrapper>
                  <FormItemWrapper>
                    <ValueWrapper>
                      <Label>Version *</Label>
                      <Controller
                        name="version"
                        control={control}
                        render={({ field }) => (
                          <Select
                            placeholder="Select Version"
                            size="large"
                            style={{ width: '100%' }}
                            {...field}
                            options={versionSelectBox}
                          />
                        )}
                      />
                      {errors.version && <ErrorMessage>{errors?.version?.message}</ErrorMessage>}
                    </ValueWrapper>
                    <HeaderWrapper>
                      <Label>NP Type *</Label>
                      <Controller
                        name="np_type"
                        control={control}
                        render={({ field }) => (
                          <Select
                            placeholder="Select NP_Type"
                            size="large"
                            style={{ width: '100%' }}
                            {...field}
                            options={npTypeSelectBox}
                          />
                        )}
                      />

                      {errors.np_type && <ErrorMessage>{errors?.np_type?.message}</ErrorMessage>}
                    </HeaderWrapper>
                  </FormItemWrapper>
                </FormWrapper>
                <FormWrapper>
                  <Label>Subscriber URI *</Label>
                  <Controller
                    name="subscriber_uri"
                    control={control}
                    render={({ field }) => <Input {...field} placeholder="Enter URI" size="large" />}
                  />
                  {errors.subscriber_uri && <ErrorMessage>{errors?.subscriber_uri?.message}</ErrorMessage>}
                </FormWrapper>
                <FormWrapper>
                  <Label>Subscriber Id *</Label>
                  <Controller
                    name="subscriber_id"
                    control={control}
                    render={({ field }) => <Input {...field} placeholder="Enter Id" size="large" />}
                  />
                  {errors.subscriber_id && <ErrorMessage>{errors?.subscriber_id?.message}</ErrorMessage>}
                </FormWrapper>
                <FormWrapper>
                  <Label>Provider Id *</Label>
                  <Controller
                    name="provider_id"
                    control={control}
                    render={({ field }) => <Input {...field} placeholder="Enter Id" size="large" />}
                  />
                  {errors.provider_id && <ErrorMessage>{errors?.provider_id?.message}</ErrorMessage>}
                </FormWrapper>
                <FormWrapper>
                  <Controller
                    name="onNetwork"
                    control={control}
                    render={({ field }) => (
                      <Checkbox {...field} checked={isChecked} onChange={handleCheckboxChange}>
                        On Network Logistics ?
                      </Checkbox>
                    )}
                  />
                  {errors.onNetwork && <ErrorMessage>{errors?.onNetwork?.message}</ErrorMessage>}
                </FormWrapper>
                {showNextSelect && (
                  <div>
                    <FormWrapper>
                      <Label>Logistics Bap URI *</Label>
                      <Controller
                        name="logistics_bap_uri"
                        control={control}
                        render={({ field }) => <Input {...field} placeholder="Enter URI" size="large" />}
                      />
                      {errors.logistics_bap_uri && <ErrorMessage>{errors?.logistics_bap_uri?.message}</ErrorMessage>}
                    </FormWrapper>
                    <FormWrapper>
                      <Label>Logistics Bap Id *</Label>
                      <Controller
                        name="logistics_bap_id"
                        control={control}
                        render={({ field }) => <Input {...field} placeholder="Enter Id" size="large" />}
                      />
                      {errors.logistics_bap_id && <ErrorMessage>{errors?.logistics_bap_id?.message}</ErrorMessage>}
                    </FormWrapper>
                    <FormWrapper>
                      <Label>Type Of Hits *</Label>
                      <Controller
                        name="type_of_hits"
                        control={control}
                        render={({ field }) => (
                          <Select
                            placeholder="Select Type of Hits"
                            size="large"
                            style={{ width: '100%' }}
                            {...field}
                            options={SelectHits}
                          />
                        )}
                      />
                      {errors.type_of_hits && <ErrorMessage>{errors?.type_of_hits?.message}</ErrorMessage>}
                    </FormWrapper>
                  </div>
                )}
              </FormInnerContainer>
              <ButtonWrapper>
                <CustomButton label={cancelBtnLabel} variant="outline" onClick={handleCancel} />
                {confirmBtnLabel && <CustomButton label={confirmBtnLabel} variant="gradient" />}
              </ButtonWrapper>
            </form>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default FormModal
